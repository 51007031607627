// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth,GoogleAuthProvider}  from "firebase/auth"
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1enyshxNw0yuNoKL4JIjcV23OGGYpWIk",
  authDomain: "ntsticks-7fa3f.firebaseapp.com",
  projectId: "ntsticks-7fa3f",
  storageBucket: "ntsticks-7fa3f.appspot.com",
  messagingSenderId: "594149698240",
  appId: "1:594149698240:web:8305d864151ac315fb5b25",
  measurementId: "G-1HPYWFY37L"
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth= getAuth(app)
export const googleProvider = new GoogleAuthProvider()
export const db=getFirestore(app)
 export const analytics = getAnalytics(app);