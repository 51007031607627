import { useEffect, useState } from "react";
import {
  products,
  more_products,
  ganesh,
  product_benifits,
} from "../../data/Data";
import { useParams } from "react-router-dom";
import {
  addToCart,
  removeItem,
  updateQuantity,
  getCartTotal,
} from "../../redux/CartSlice";
import { useDispatch } from "react-redux";
import Video from "../../components/Video";
import ProceedToCheckoutView from "../../components/ProceedToCheckoutView";
import Heading from "../../common/Heading";
import { Helmet } from "react-helmet";
import { analytics } from "../../config/firebase";
import { logEvent } from "firebase/analytics";
import "./product_item.css";
import ScrollToTop from "../../components/ScrollToTop";

// import { useRef } from "react";
// import { useMouseOverZoom } from "../../hooks/hoverhook";
// import image from "../../image.jpg";
// import "../../css/hover.css";

export default function ProductItem() {
  const all_products = [...products, ...more_products];

  let [count, setCount] = useState(1);

  function incrementCount() {
    count = count + 1;
    setCount(count);
    setQty(count);
  }
  function decrementCount() {
    count = count - 1;
    setCount(count);
    setQty(count);
  }
  // const source = useRef();
  // const target = useRef();
  // const cursor = useRef();

  // // call the custom hook
  // useMouseOverZoom(source, target, cursor);

  const [selectedProduct, setSelestedProduct] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  let { id } = useParams();
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const handleAddToCart = (item) => {
    console.log(item);
    let totalPrice = qty * item.price;
    const tempProduct = {
      ...item,
      quantity: qty,
      totalPrice,
    };
    dispatch(addToCart(tempProduct));
    dispatch(getCartTotal());
  };

  function setImage(url) {
    setMainImage(url);
  }

  useEffect(() => {
    logEvent(analytics, `User Checked Our item ${id}`);
    if (id == "Eco_Friendly_Ganesh_Kit") {
      setSelestedProduct(ganesh);
    } else {
      for (let i = 0; i < all_products.length; i++) {
        if (id == all_products[i].product_name_key) {
          setSelestedProduct(all_products[parseInt(all_products[i].id)]);
          return;
        }
      }
    }
  }, []);

  return (
    <div>
      {selectedProduct && (
        <Helmet>
          <title>{selectedProduct.meta_title}</title>
          <meta name="description" content={selectedProduct.meta_description} />
        </Helmet>
      )}
      <Heading title="Home" subtitle="Products" />
      {selectedProduct && (
        <div className="product_devision_view product_devision_view_mobile">
          <div
            style={{ flex: "2", display: "flex" }}
            className="product_devision_view_mobile"
          >
            {id.includes("Ganesh") &&<div className="product_gallery_mobile">
              {selectedProduct.gallery.map((item) => (
                <img
                style={{objectFit:"contain"}}
                  className="product_img pointer"
                  height={100}
                  width={80}
                  src={item.url_s}
                  onClick={() => setImage(item.url_s)}
                  //  ref={source}
                />
              ))}
            </div>}

            {/* <div className="col-span-12 md:col-span-4 md:col-start-9 border-t-8 md:border-t-0 md:border-l-8 border-indigo-500 relative z-10">
          
          <div ref={cursor} className="border border-sky-500 absolute pointer-events-none" />
          <canvas ref={target} className="absolute pointer-events-none bottom-full translate-y-1/2 left-1/2 -translate-x-1/2 md:translate-y-0 md:translate-x-0 md:bottom-16 md:-left-48 border-8 border-indigo-500 w-64 h-64 z-10 bg-gray-200" />{" "}
        </div> */}

            <div>
              <img
                //  ref={source}
                style={{objectFit:"contain"}}
                className="product_img object-cover product_img_mobile"
                height={900}
                width={700}
                src={mainImage ? mainImage : selectedProduct.product_img}
              />
            </div>
          </div>
          <div
            className="white-card "
            style={{ margin: "10px", padding: "10px" }}
          >
            <label className="a-size-extra-large">
              {selectedProduct.product_name_detail
                ? selectedProduct.product_name_detail
                : selectedProduct.product_name}
            </label>
            <br />
            <label className="amount_item_view">
              ₹ {selectedProduct.price}.00
            </label>

            {/* <div
              className="d-flex align-items-center justify-content-center mb-1"
              style={{ width: "max-content" }}
            >
              {selectedProduct.stars}
              <small>({selectedProduct.ratingCount})</small>
            </div> */}

            <div style={{ display: "flex" }}>
              {product_benifits.map((item) => (
                <div className="properties">
                  <div style={{ display: "grid", justifyItems: "center" }}>
                    <img src={item.image} />
                    <label>{item.name}</label>
                  </div>
                </div>
              ))}
            </div>
            {!!!selectedProduct.out_of_stock?<p class="stock in-stock">In stock</p>:
            <p class="stock out-stock">Out Of stock</p>}
            <br />
            <div>
            <div className="input-group quantity " style={{ width: "100px" }}>
              <div className="input-group-btn">
                <button
                  className="btn btn-sm btn-primary btn-minus"
                  onClick={() => decrementCount()}
                >
                  <i className="fa fa-minus"></i>
                </button>
              </div>
              <input
                type="text"
                className="form-control form-control-sm bg-secondary border-0 text-center"
                value={count || 1}
              />
              <div className="input-group-btn">
                <button
                  className="btn btn-sm btn-primary btn-plus"
                  onClick={() => incrementCount()}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
            <span>
              <button
                disabled={selectedProduct.out_of_stock}
                style={{ width: "auto" }}
                onClick={() => handleAddToCart(selectedProduct)}
                className="btn btn-block btn-primary font-weight-bold my-3 py-3"
              >
                Add to Cart
              </button>
            </span>
            </div>
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" >
                  Description
                </a>
              </li>
            </ul>
            <label className="a-size-small">
              {selectedProduct.description}
            </label>
            {/* <button onClick={() => handleAddToCart(selectedProduct)}>Add to Cart</button> */}
          </div>
          {/* <Video className="videoItemView" url="../../../assets/nt/ntvideo2.mp4" width="100%" height="60%"/> */}
        </div>
      )}
      {/* <img /> */}
      <ProceedToCheckoutView />
      <ScrollToTop />
    </div>
  );
}
