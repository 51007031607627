import React, { useEffect, useMemo, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { products, more_products } from "../../data/Data";
import { Link } from "react-router-dom";
import Heading from "../../common/Heading";
import { useDispatch } from "react-redux";
import { addToCart, getCartTotal } from "../../redux/CartSlice";
import { analytics, auth } from "../../config/firebase";
import checkUserSignedIn from "../../util/AuthUtil";
import { useNavigate } from "react-router-dom";
import ProceedToCheckoutView from "../../components/ProceedToCheckoutView";
import { logEvent } from "firebase/analytics";
import ScrollToTop from "../../components/ScrollToTop";
import "./shop.css"



export default function Shop() {
  const all_products = [...products, ...more_products];
  const [filterArray, setFilterArray] = useState([]);
  const [filterArrayRange, setFilterArrayRange] = useState([]);
  const [filterArrayWeight, setFilterArrayWeight] = useState([]);
  const [authUser, setAuthUser] = useState(null);

  // const [filteredUsers, setFilteredUsers] = useState(all_products);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const handleAddToCart = (item) => {
    console.log(item);
    let totalPrice = qty * item.price;
    const tempProduct = {
      ...item,
      quantity: qty,
      totalPrice,
    };
    dispatch(addToCart(tempProduct));
    dispatch(getCartTotal());
  };
useEffect(()=>{
  logEvent(analytics,`User reached shop page`)
    checkUserSignedIn(auth,setAuthUser)
},[])


  // useEffect(()=>{
  //   checkUserSignedIn(auth,setAuthUser)
  //   if(!authUser)
  //     navigate("/login");
  // },[authUser])

  

  const filteredUsers = useMemo(() => {
    return all_products.filter(filteration);
  }, [all_products, filterArray, filterArrayRange, filterArrayWeight]);

  const onFilterChange = (evt, filter, typeIndex) => {
    switch (typeIndex.id) {
      case 2:
        {
          if (evt.target.checked)
            setFilterArray([...filterArray, filter.variety]);
          else
            setFilterArray((filterArray) =>
              filterArray.filter(function (item) {
                return item !== filter.variety;
              })
            );
        }
        break;
      case 1:
        {
          if (evt.target.checked) {
            setFilterArrayRange([...filterArrayRange, filter.variety]);
          } else {
            setFilterArrayRange((filterArrayRange) =>
              filterArrayRange.filter(function (item) {
                return item !== filter.variety;
              })
            );
          }
        }
        break;
      case 3: {
        if (evt.target.checked)
          setFilterArrayWeight([...filterArrayWeight, filter.variety]);
        else
          setFilterArrayWeight((filterArrayWeight) =>
            filterArrayWeight.filter(function (item) {
              return item !== filter.variety;
            })
          );
      }
    }
  };

  function filteration(item) {
    if (
      !filterArray.length &&
      !filterArrayRange.length &&
      !filterArrayWeight.length
    ) {
      return true;
    } else {
      if (filterArray.length) {
        return nameCheck(item);
      } else if (filterArrayRange.length) {
        return rangeCheck(item);
      } else {
        return weightCheck(item);
      }
    }
  }

  function rangeCheck(item) {
    return filterArrayRange.find(function (eachKey) {
      if (priceWithInRange(item.price, eachKey)) {
        if (filterArrayWeight.length) return weightCheck(item);
        else return true;
      }
    });
  }

  function weightCheck(item) {
    return filterArrayWeight.find(function (eachKey) {
      return weightWithInRange(item.weight, eachKey);
    });
  }

  function weightWithInRange(weight, range) {
    console.log(weight + "---weight----" + range);
    return parseInt(weight) < parseInt(range);
  }

  function priceWithInRange(price, range) {
    console.log(price + "---price----" + range);
    let rangeArr = range.split("-");
    if (price >= parseInt(rangeArr[0]) && price < parseInt(rangeArr[1]))
      return true;
    else return false;
  }

  function nameCheck(item) {
    return filterArray.find(function (eachKey) {
      if (item.product_name.toLowerCase().includes(eachKey.toLowerCase())) {
        if (filterArrayRange.length) return rangeCheck(item);
        else if (filterArrayWeight.length) return weightCheck(item);
        else return true;
      }
    });
  }

  return (
    <>
      <Heading title="Home" subtitle="Shop" />
      <div className="container-fluid">
        <div className="row px-xl-5">
          

          <div className="col-lg-9 col-md-8">
            <div className="row pb-3">
              {/* <div className="col-12 pb-1">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <div>
                    <button className="btn btn-sm btn-light">
                      <i className="fa fa-th-large"></i>
                    </button>
                    <button className="btn btn-sm btn-light ml-2">
                      <i className="fa fa-bars"></i>
                    </button>
                  </div>
                  <div className="ml-2">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn btn-sm btn-light dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Sorting
                      </button>
                    </div>
                    <div className="btn-group ml-2">
                      <button
                        type="button"
                        className="btn btn-sm btn-light dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        Showing
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
              {filteredUsers.map((item, index) => (
                <div className="col-lg-2 col-md-6 col-sm-6 pb-1" key={index}>
                  <div className="product-item bg-light mb-4">
                    <div className="product-img position-relative overflow-hidden">
                      <img
                        className="img-fluid w-100 img-resize"
                        src={item.product_img}
                        alt="img"
                      />
                      <div className={'product-action' +(item.out_of_stock?' outOfStock':"")}>
                      {!!!item.out_of_stock&&<><Link
                          className="btn btn-outline-dark btn-square"
                          onClick={() => handleAddToCart(item)}
                        >
                          <i className="fa fa-shopping-cart"></i>
                        </Link>
                        <Link className="btn btn-outline-dark btn-square">
                          <i className="far fa-heart"></i>
                        </Link></>}
                        {item.out_of_stock&& <span style={{color:"red",fontWeight:"700"}}>OUT OF STOCK</span>}
                      </div>
                    </div>
                    <div className="text-center py-4">
                      <Link className="h6 text-decoration-none text-truncate white-space" to={`/item/${item.product_name_key}`}>
                        {item.product_name}
                      </Link>
                      <div className="d-flex align-items-center justify-content-center mt-2">
                        <h5>₹{item.price}</h5>
                        <h6 className="text-muted ml-2">
                          <del>₹{item.mainPrice}</del>
                        </h6>
                      </div>
                      <div className="d-flex align-items-center justify-content-center mb-1">
                        {item.star}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Sidebar
            onFilterChange={(evt, filter, typeIndex) =>
              onFilterChange(evt, filter, typeIndex)
            }
          />
        </div>
      </div>
      <ProceedToCheckoutView/>
      <ScrollToTop />

    </>
  );
}
