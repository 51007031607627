import React, { useEffect, useState } from "react";
import { products } from "../data/Data";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addToCart, getCartTotal } from "../redux/CartSlice";
import { logEvent } from "firebase/analytics";
import { analytics } from "../config/firebase";
import "../css/components.css";

export default function Product() {
  const dispatch = useDispatch();
  const [qty, setQty] = useState(1);
  const navigate = useNavigate();

  const handleAddToCart = (e, product) => {
    if(product.out_of_stock){
      return
    }
    e.stopPropagation();
    let totalPrice = qty * product.price;
    const tempProduct = {
      ...product,
      quantity: qty,
      totalPrice,
    };
    dispatch(addToCart(tempProduct));
    dispatch(getCartTotal());
  };

  useEffect(() => {
    logEvent(analytics, "User Entered in the project");
  }, []);

  function wayToItem(item) {
    navigate(`/item/${item.product_name_key}`);
  }

  const handleLikeClick = (e, index) => {
    e.stopPropagation(); // Prevents triggering parent click events
    products[index]["liked"] = !!!products[index]["liked"]; // Toggle the liked state
  };

  return (
    <>
      <div className="container-fluid pt-5 pb-3">
        <div className="row px-xl-5">
          {products.map((item, index) => (
            <div className="col-lg-2 col-md-4 col-sm-6 pb-1" key={index}>
              <div
                className="product-item bg-light mb-4"
                style={{ cursor: "pointer"}}
                onClick={() => wayToItem(item)}
              >
                <div className="product-img position-relative overflow-hidden">
                  <img
                    className="img-fluid w-100 img-resize"
                    src={item.product_img}
                    alt="img"
                    loading="lazy"
                  />
                  <div className={'product-action' +(item.out_of_stock?' outOfStock':"")} >
                    {!!!item.out_of_stock&&<><Link
                      className="btn btn-outline-dark btn-square"
                      onClick={(e) => handleAddToCart(e, item)}
                    >
                      <i className="fa fa-shopping-cart"></i>
                    </Link>
                    <Link
                      onClick={(e) => handleLikeClick(e, index)}
                      className="heart-container btn btn-outline-dark btn-square"
                    >
                      <svg
                        className={` heart-like ${item["liked"] ? "liked" : ""}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="22"
                        height="22"
                      >
                        <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                      </svg>
                    </Link></>}
                    {/* <Link className="btn btn-outline-dark btn-square">
                      <i className="fa fa-sync-alt"></i>
                    </Link>
                    <Link className="btn btn-outline-dark btn-square">
                      <i className="fa fa-search"></i>
                    </Link> */}
                   {item.out_of_stock&& <span style={{color:"red",fontWeight:"700"}}>OUT OF STOCK</span>}
                  </div>
                </div>
                <div className="text-center py-4">
                  <Link
                    className="h6 text-decoration-none text-truncate"
                    to={`/item/${item.product_name_key}`}
                  >
                    {item.product_name}
                  </Link>
                  <div className="d-flex align-items-center justify-content-center mt-2">
                    <h5>₹{item.price}</h5>
                    <h6 className="text-muted ml-2">
                      <del>₹{item.mainPrice}</del>
                    </h6>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-center mb-1">
                    {item.stars}
                    <small>({item.ratingCount})</small>
                  </div> */}
                </div>
              </div>
            </div>
          ))}

          <div className="show_more_container">
            <Link className="show_more" to="/shop">
              Show More
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
