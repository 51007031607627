import React from "react";
import {  useNavigate } from "react-router-dom";
import Heading from "../common/Heading";

export default function Blogs(props) {
    const navigate = useNavigate();


    function redirectToBlogsPage(){
        navigate('/blogs')
    }
    const {blogsData,isBlogsPage}=props
  return (
    <>
    {isBlogsPage?<Heading title="Home" subtitle="Blogs" />:
    <div style={{display:"flex"}}><hr/><h2>Blogs</h2><hr/></div>}
      <div className="container-fluid pt-5" >
        <div className="row px-xl-5 pb-3">
          {blogsData&&blogsData.map((item, index) => (
            <div className={"col-md-4 col-sm-6 pb-1"+(isBlogsPage?" col-lg-6":" col-lg-3")} key={index}>
                <div className="blog-item align-items-center mb-4">
                  <div
                    className="overflow-hidden"
                    style={{ height: "auto",margin:"10px" }}
                  >
                    <label><b>{item.que}</b></label>
                  </div>
                  <div className="flex-fill pl-3">
                    <span dangerouslySetInnerHTML={{ __html:item.ans}}>
                    </span>
                    {!!!isBlogsPage&&<span style={{textAlign:"right",whiteSpace: "pre"}}><a href="" style={{color:"#48e148",fontWeight:"700"}} onClick={redirectToBlogsPage}>Show More</a></span>}
                  </div>
                </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
