import React from "react";
import { Link } from "react-router-dom";
import "../css/common.css"

export default function Heading({ title, subtitle }) {
  return (
    <>
      <div className="container-fluid" style={{marginTop: "20px"}}>
        <div className="row px-xl-5">
          <div className="col-12">
            <nav className="breadcrumb bg-light mb-30 header-links">
              <div>
              <Link to="/" className="breadcrumb-item text-dark display-block">
                {title}
              </Link>
              <Link className="breadcrumb-item text-dark display-block">{subtitle}</Link>
              </div>
              <div style={{width:"90%"}} className="header-class">
              {subtitle!="My Orders"&&<Link to="/myorders" className="breadcrumb-item text-dark my_order_header">My Orders</Link>}
              </div>

            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
