
import {  onAuthStateChanged } from "firebase/auth";


const checkUserSignedIn=(auth,setAuthUser)=>{
    const listen=onAuthStateChanged(auth,(user)=>{
      if(user){
        setAuthUser(user)
      }
      else{
        setAuthUser(null)
      }
    })
    return ()=> {
      listen();
    }
  }
  export default checkUserSignedIn