import ScrollToTop from "../../components/ScrollToTop";

export default function ReturnRefund() {
  return (
    <>
      {/* <div className="top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <marquee
                behavior="scroll"
                direction="left"
                loop="-1"
                className="scrolling-text"
              >
                NON - MILLENNIAL MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp;
                FREE SHIPPING PAN INDIA &nbsp;&nbsp;&nbsp;&nbsp;NON - MILLENNIAL
                MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN
                INDIA &nbsp;&nbsp;&nbsp;&nbsp;NON - MILLENNIAL MILLENNIALS CLUB
                &nbsp; &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN INDIANON -
                MILLENNIAL MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp; FREE
                SHIPPING PAN INDIA &nbsp;&nbsp;&nbsp;&nbsp;NON - MILLENNIAL
                MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN
                INDIANON - MILLENNIAL MILLENNIALS CLUB &nbsp; &nbsp; &nbsp;
                &nbsp; FREE SHIPPING PAN INDIANON - MILLENNIAL MILLENNIALS CLUB
                &nbsp; &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN INDIANON -
                MILLENNIAL MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp; FREE
                SHIPPING PAN INDIANON - MILLENNIAL MILLENNIALS CLUB &nbsp;
                &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN INDIANON - MILLENNIAL
                MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN
                INDIANON - MILLENNIAL MILLENNIALS CLUB &nbsp; &nbsp; &nbsp;
                &nbsp; FREE SHIPPING PAN INDIA
              </marquee>
            </div>
          </div>
        </div>
      </div> */}
          <p style={{ textAlign: "start" }}>&nbsp;</p>

      <div className="container">
        <div className="policy">
          <p style={{ textAlign: "center" }}>
            <span style={{fontSize:"17pt"}}>
              <span style={{ fontFamily: "Impact,monospace" }}>
                <span style={{ color: "#000" }}>
                  <strong>
                    <u>RETURN AND REFUND POLICY</u>
                  </strong>
                </span>
              </span>
            </span>
          </p>
          <p>&nbsp;</p>
          <p style={{ textAlign: "start" }}>&nbsp;</p>
          <hr />
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>Welcome to </strong>
                </span>
              </span>
            </span>
            <span style={{ fontSize: "12pt" }}>
              <span style={{fontFamily:"Lobster,cursive"}}>
                <span style={{color:"#000",fontFamily:"Times New Roman,Times,serif"}}>
                  <strong>Nt sticks</strong>
                </span>
              </span>
            </span>
            <span style={{ fontSize: "12pt" }}>
              <span style={{fontFamily:"&quot"}}>
                <span style={{ color: "#000" }}>
                  <strong>!</strong>
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "center" }}>
            <span style={{ fontSize: "12pt" }}>
              <span style={{ fontFamily: "Impact,monospace" }}>
                <span style={{ color: "#000" }}>
                  <strong>RETURN POLICY</strong>
                </span>
              </span>
            </span>
          </p>
          <p>&nbsp;</p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  In Ntsticks, we strive to give you the very best shopping
                  experience possible. However, considering that opened or
                  damaged products cannot be reused, we cannot accept exchange
                  or return of opened or used products once sold or delivered.
                  We are not responsible for any damage caused after delivery.
                  In case of any problem or quality issue with the product, we
                  exchange the product. All you need to do is email us within 24
                  hours of delivery.
                </span>
              </span>
            </span>
          </p>
          <p>
            <span style={{fontSize:"11pt"}}>
              <span style={{ fontFamily: "Impact,monospace" }}>
                <span style={{ color: "#666" }}>
                  Please review our privacy-policy so that you may understand
                  our privacy practices.&nbsp;
                </span>
              </span>
            </span>
          </p>
          <h4 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  All products once purchased are non-refundable. In general, an
                  item may be eligible for return within 7 days from the date of
                  Product Delivery if it fulfills any of the following
                  conditions:&nbsp;
                </span>
              </span>
            </span>
          </h4>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <h4 style={{ textAlign: "start" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ fontSize: "12px" }}>
                    <span style={{ color: "#666" }}>
                      Sold in a physically damaged condition.&nbsp;
                    </span>
                  </span>
                </span>
              </h4>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <h4 style={{ textAlign: "start" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ fontSize: "12px" }}>
                    <span style={{ color: "#666" }}>
                      Has missing parts or accessories.&nbsp;
                    </span>
                  </span>
                </span>
              </h4>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <h4 style={{ textAlign: "start" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ fontSize: "12px" }}>
                    <span style={{ color: "#666" }}>
                      Product packaging not opened.&nbsp;
                    </span>
                  </span>
                </span>
              </h4>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <h4 style={{ textAlign: "start" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ fontSize: "12px" }}>
                    <span style={{ color: "#666" }}>Product not used.</span>
                  </span>
                </span>
              </h4>
            </li>
          </ul>
          <p>&nbsp;</p>
          <p style={{ textAlign: "start" }}>&nbsp;</p>
          <hr />
          <p>&nbsp;</p>
          <p style={{ textAlign: "center" }}>
            <span style={{ fontSize: "12pt" }}>
              <span style={{ fontFamily: "Impact,monospace" }}>
                <span style={{ color: "#000" }}>
                  <strong>REFUND POLICY</strong>
                </span>
              </span>
            </span>
          </p>
          
          <h4
            style={{
              marginLeft: "9px",
              marginRight: "9px",
              textAlign: "start",
            }}
          >
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  Returns will only be accepted for products that have been
                  damaged in transit or products that have physical defects or
                  in case of incorrect products being shipped. An email with
                  photos of the damaged/incorrect product should be sent within
                  the 48 hours time in order for us to accept the request.
                </span>
              </span>
            </span>
          </h4>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <h4 style={{marginRight:"9px",textAlign:"start"}}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ fontSize: "12px" }}>
                    <span style={{ color: "#666" }}>
                      If you made an online payment, we will refund the amount
                      back in the original source (debit/credit card, bank,
                      wallet, etc) within 7 working days of receiving the
                      request from you.
                    </span>
                  </span>
                </span>
              </h4>
            </li>
          </ul>
          <p>
            <br />
            &nbsp;
          </p>{" "}
        </div>
      </div>

      <ScrollToTop />
    </>
  );
}
