import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { nav, topdropdown } from "../data/Data";
import { useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import checkUserSignedIn from "../util/AuthUtil";
import "../css/topbar.css"
import useComponentVisible from "../components/useComponentVisible";

export default function TopBar(props) {
  const [authUser, setAuthUser] = useState(null);
  const [showDropDown, setShowDropDown] = useState(false);
  const { isSticky } = props;
  const { totalItems } = useSelector((state) => state.cart);
  const token = localStorage.getItem("token");
  const { ref, isComponentVisible,setIsComponentVisible } = useComponentVisible(showDropDown);

  const navigate = useNavigate();

  useEffect(() => {
    setShowDropDown(isComponentVisible)
  }, [isComponentVisible]);

  useEffect(() => {
    checkUserSignedIn(auth, setAuthUser);
    // if(!authUser)
    //   navigate("/login");
  }, [authUser]);

  const logOut = () => {
    try {
      signOut(auth);
    } catch (err) {
      console.log(err);
    }
    //setProfile(null);
  };
  const profile = () => {
    setShowDropDown(!!!showDropDown)
    setIsComponentVisible(!!!showDropDown)
  };
  const login = () => {
    navigate("/login");
    profile()
  };
  const myOrders = () => {
    navigate("/myorders");
    profile()

  };

  function clearTokenFromStorage() {
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
  }

  return (
    <>
      <div className={`container-fluid ${isSticky ? "sticky-header" : ""}`}>
        {/* <div className="row bg-secondary py-1 px-xl-5">
          <div className="col-lg-6 d-none d-lg-block">
            <div className="d-inline-flex align-items-center h-100">
              {nav.slice(2, 6).map((top, index) => (
                <Link to={top.path} className="text-body mr-3" key={index}>
                  {top.text}
                </Link>
              ))}
            </div>
          </div>
          <div className="col-lg-6 text-center text-lg-right">
            <div className="d-inline-flex align-items-center">
              {topdropdown.map((btn, key) => (
                <div className="btn-group" key={key}>
                  <button
                    type="button"
                    className="btn btn-sm btn-light dropdown-toggle"
                  >
                    {btn.btn}
                  </button>
                </div>
              ))}
            </div>
            <div className="d-inline-flex align-items-center d-block d-lg-none">
              <a href="" className="btn px-0 ml-2">
                <i className="fas fa-heart text-dark"></i>
                <span
                  className="badge text-dark border border-dark rounded-circle"
                  style={{ paddingBottom: "2px" }}
                >
                  0
                </span>
              </a>
              <a href="" className="btn px-0 ml-2">
                <i className="fas fa-shopping-cart text-dark"></i>
                <span
                  className="badge text-dark border border-dark rounded-circle"
                  style={{ paddingBottom: "2px" }}
                >
                  0
                </span>
              </a>
            </div>
          </div>
        </div> */}
        <div className="row align-items-center bg-light py-3 px-xl-5 d-none d-lg-flex">
          <div className="col-lg-4">
            <Link to="/" className="text-decoration-none">
              <span className="h1 text-uppercase text-primary bg-dark px-2">
                NT
              </span>
              <span className="h1 text-uppercase text-dark bg-primary px-2 ml-n1">
                Sticks
              </span>
            </Link>
          </div>
          <div className="col-lg-4 col-6 text-left">
            <form action="">
              <div
                className="input-group"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Search for products"
                /> */}
                {/* <div className="input-group-append">
                  <span className="input-group-text bg-transparent text-primary">
                    <i className="fa fa-search"></i>
                  </span>
                </div> */}
                <img src="../../assets/nt/ntsticklogo.png" alt="NT Sticks" />
              </div>
            </form>
          </div>

          <div className="col-lg-4 col-6 text-right flexdisplay">
            <div>
              {/* <p className="m-0">Customer Service</p>
            <h5 className="m-0">6264208349</h5> */}
              
              <div class="navbar">
        <div class="profile">
        <img
                  className="logout-button"
                  src="../../assets/nt/person.svg"
                  onClick={profile}
                  alt="Profile" class="profile-icon" id="profile-icon"
                ></img>
            {/* <img src="profile-icon.png" alt="Profile" class="profile-icon" id="profile-icon"/> */}
            {showDropDown&&isComponentVisible&&<div ref={ref} class="dropdown-menu" id="dropdown-menu" style={{display:"block"}}>
                {authUser&&<><a href="#" onClick={myOrders} class="dropdown-item">My Orders</a>
                <a onClick={logOut} class="dropdown-item">Logout</a></>}
                {!authUser&&<a onClick={login} class="dropdown-item">Login</a>}
            </div>}
        </div>
    </div>
                
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
