import { logEvent } from 'firebase/analytics';
import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import { analytics } from '../config/firebase';

class Video extends Component {

    eventOnPlay=(e)=> {
        logEvent(analytics, 'yt played');
        console.log('yt played')
    }
    render () {
        return (
        <div className={"player-wrapper "+this.props.className}>
            <ReactPlayer
            className='react-player'
            url= {this.props.url}
            width={this.props.width}
            height={this.props.height}
            controls = {true}
            onStart={this.eventOnPlay}
            />
        </div>
        )
    }
}

export default Video;