import ScrollToTop from "../../components/ScrollToTop";

export default function Terms() {
  return (
    <>
          <p style={{ textAlign: "start" }}>&nbsp;</p>

      <div className="container">
        <div className="policy">
          <p style={{ textAlign: "center" }}>
            <span style={{ fontSize: "17pt" }}>
              <span style={{ fontFamily: "Impact,monospace" }}>
                <span style={{ color: "#000" }}>
                  <strong>
                    <u>TERMS AND CONDITIONS</u>
                  </strong>
                </span>
              </span>
            </span>
          </p>
          <p>
            <br />
            &nbsp;
          </p>
          <hr />
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12pt" }}>
              <span style={{ fontFamily: "&quot" }}>
                <span style={{ color: "#000" }}>
                  <strong>
                    <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                      Welcome to{" "}
                    </span>
                  </strong>
                </span>
              </span>
            </span>
            <span style={{ fontSize: "12pt" }}>
              <span style={{ fontFamily: "Lobster,cursive" }}>
                <span
                  style={{
                    color: "#000",
                    fontFamily: "Times New Roman,Times,serif",
                  }}
                >
                  <strong>Nt sticks</strong>
                </span>
              </span>
            </span>
            <span style={{ fontSize: "12pt" }}>
              <span style={{ fontFamily: "&quot" }}>
                <span style={{ color: "#000" }}>
                  <strong>!</strong>
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  These terms and conditions outline the rules and regulations
                  for the use of Nt sticks&#39;s Website, located at
                  https://Ntsticks.com/.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  By accessing this website we assume you accept these terms and
                  conditions. Do not continue to use Nt sticks if you do not
                  agree to take all of the terms and conditions stated on this
                  page.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  The following terminology applies to these Terms and
                  Conditions, Privacy Statement and Disclaimer Notice and all
                  Agreements: &quot;Client&quot;, &quot;You&quot; and
                  &quot;Your&quot; refers to you, the person log on this website
                  and compliant to the Company&#39;s terms and conditions.
                  &quot;The Company&quot;, &quot;Ourselves&quot;,
                  &quot;We&quot;, &quot;Our&quot; and &quot;Us&quot;, refers to
                  our Company. &quot;Party&quot;, &quot;Parties&quot;, or
                  &quot;Us&quot;, refers to both the Client and ourselves. All
                  terms refer to the offer, acceptance and consideration of
                  payment necessary to undertake the process of our assistance
                  to the Client in the most appropriate manner for the express
                  purpose of meeting the Client&#39;s needs in respect of
                  provision of the Company&#39;s stated services, in accordance
                  with and subject to, prevailing law of. Any use of the above
                  terminology or other words in the singular, plural,
                  capitalization and/or he/she or they, are taken as
                  interchangeable and therefore as referring to the same.
                </span>
              </span>
            </span>
          </p>
          <h3 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>Cookies</strong>
                </span>
              </span>
            </span>
          </h3>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  We employ the use of cookies. By accessing Nt sticks, you
                  agreed to use cookies in agreement with the Nt sticks&#39;s
                  Privacy Policy.&nbsp;
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  Most interactive websites use cookies to let us retrieve the
                  user&#39;s details for each visit. Cookies are used by our
                  website to enable the functionality of certain areas to make
                  it easier for people visiting our website. Some of our
                  affiliate/advertising partners may also use cookies.
                </span>
              </span>
            </span>
          </p>
          <h3 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>License</strong>
                </span>
              </span>
            </span>
          </h3>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  Unless otherwise stated, Nt sticks and/or its licensors own
                  the intellectual property rights for all material on Nt
                  sticks. All intellectual property rights are reserved. You may
                  access this from Nt sticks for your own personal use subject
                  to restrictions set in these terms and conditions.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>You must not:</span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    Republish material from Nt sticks
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    Sell, rent or sub-license material from Nt sticks
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    Reproduce, duplicate or copy material from Nt sticks
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    Redistribute content from Nt sticks
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>&nbsp;</p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  Parts of this website offer an opportunity for users to post
                  and exchange opinions and information in certain areas of the
                  website. Nt sticks does not filter, edit, publish or review
                  Comments prior to their presence on the website. Comments do
                  not reflect the views and opinions of Nt sticks,its agents
                  and/or affiliates. Comments reflect the views and opinions of
                  the person who posts their views and opinions. To the extent
                  permitted by applicable laws, Nt sticks shall not be liable
                  for the Comments or for any liability, damages or expenses
                  caused and/or suffered as a result of any use of and/or
                  posting of and/or appearance of the Comments on this website.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  Nt sticks reserves the right to monitor all Comments and to
                  remove any Comments which can be considered inappropriate,
                  offensive or causes breach of these Terms and Conditions.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  You warrant and represent that:
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    You are entitled to post the Comments on our website and
                    have all necessary licenses and consents to do so;
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    The Comments do not invade any intellectual property right,
                    including without limitation copyright, patent or trademark
                    of any third party;
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    The Comments do not contain any defamatory, libelous,
                    offensive, indecent or otherwise unlawful material which is
                    an invasion of privacy
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    The Comments will not be used to solicit or promote business
                    or custom or present commercial activities or unlawful
                    activity.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  You hereby grant Nt sticks a non-exclusive license to use,
                  reproduce, edit and authorize others to use, reproduce and
                  edit any of your Comments in any and all forms, formats or
                  media.
                </span>
              </span>
            </span>
          </p>
          <h3 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>Hyperlinking to our Content</strong>
                </span>
              </span>
            </span>
          </h3>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  The following organizations may link to our Website without
                  prior written approval:
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>Government agencies;</span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>Search engines;</span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>News organizations;</span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    Online directory distributors may link to our Website in the
                    same manner as they hyperlink to the Websites of other
                    listed businesses; and
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    System wide Accredited Businesses except soliciting
                    non-profit organizations, charity shopping malls, and
                    charity fundraising groups which may not hyperlink to our
                    Website.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  These organizations may link to our home page, to publications
                  or to other Website information so long as the link: (a) is
                  not in any way deceptive; (b) does not falsely imply
                  sponsorship, endorsement or approval of the linking party and
                  its products and/or services; and (c) fits within the context
                  of the linking party&#39;s site.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  We may consider and approve other link requests from the
                  following types of organizations:
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    commonly-known consumer and/or business information sources;
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    dot.com community sites;
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    associations or other groups representing charities;
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    online directory distributors;
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>internet portals;</span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    accounting, law and consulting firms; and
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    educational institutions and trade associations.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  We will approve link requests from these organizations if we
                  decide that: (a) the link would not make us look unfavorably
                  to ourselves or to our accredited businesses; (b) the
                  organization does not have any negative records with us; (c)
                  the benefit to us from the visibility of the hyperlink
                  compensates the absence of Nt sticks; and (d) the link is in
                  the context of general resource information.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  These organizations may link to our home page so long as the
                  link: (a) is not in any way deceptive; (b) does not falsely
                  imply sponsorship, endorsement or approval of the linking
                  party and its products or services; and (c) fits within the
                  context of the linking party&#39;s site.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  If you are one of the organizations listed in paragraph 2
                  above and are interested in linking to our website, you must
                  inform us by sending an email to Nt sticks. Please include
                  your name, your organization name, contact information as well
                  as the URL of your site, a list of any URLs from which you
                  intend to link to our Website, and a list of the URLs on our
                  site to which you would like to link. Wait 2-3 weeks for a
                  response.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  Approved organizations may hyperlink to our Website as
                  follows:
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    By use of our corporate name; or
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    By use of the uniform resource locator being linked to; or
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    By use of any other description of our Website being linked
                    to that makes sense within the context and format of content
                    on the linking party&#39;s site.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  No use of Nt sticks&#39;s logo or other artwork will be
                  allowed for linking absent a trademark license agreement.
                </span>
              </span>
            </span>
          </p>
          <h3 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>iFrames</strong>
                </span>
              </span>
            </span>
          </h3>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  Without prior approval and written permission, you may not
                  create frames around our Web Pages that alter in any way the
                  visual presentation or appearance of our Website.
                </span>
              </span>
            </span>
          </p>
          <h3 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>Content Liability</strong>
                </span>
              </span>
            </span>
          </h3>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  We shall not be held responsible for any content that appears
                  on your Website. You agree to protect and defend us against
                  all claims that are rising on your Website. No link(s) should
                  appear on any Website that may be interpreted as libelous,
                  obscene or criminal, or which infringes, otherwise violates,
                  or advocates the infringement or other violation of, any third
                  party rights.
                </span>
              </span>
            </span>
          </p>
          <h3 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>Reservation of Rights</strong>
                </span>
              </span>
            </span>
          </h3>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  We reserve the right to request that you remove all links or
                  any particular link to our Website. You approve to immediately
                  remove all links to our Website upon request. We also reserve
                  the right to amend these terms and conditions and its linking
                  policy at any time. By continuously linking to our Website,
                  you agree to be bound to and follow these linking terms and
                  conditions.
                </span>
              </span>
            </span>
          </p>
          <h3 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>Removal of links from our website</strong>
                </span>
              </span>
            </span>
          </h3>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  If you find any link on our Website that is offensive for any
                  reason, you are free to contact and inform us any moment. We
                  will consider requests to remove links but we are not
                  obligated to or so or to respond to you directly.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  We do not ensure that the information on this website is
                  correct, we do not warrant its completeness or accuracy; nor
                  do we promise to ensure that the website remains available or
                  that the material on the website is kept up to date.
                </span>
              </span>
            </span>
          </p>
          <h3 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>Disclaimer</strong>
                </span>
              </span>
            </span>
          </h3>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  To the maximum extent permitted by applicable law, we exclude
                  all representations, warranties and conditions relating to our
                  website and the use of this website. Nothing in this
                  disclaimer will:
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    limit or exclude our or your liability for death or personal
                    injury;
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    limit or exclude our or your liability for fraud or
                    fraudulent misrepresentation;
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    limit any of our or your liabilities in any way that is not
                    permitted under applicable law; or
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12px" }}>
                  <span style={{ color: "#666" }}>
                    exclude any of our or your liabilities that may not be
                    excluded under applicable law.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  The limitations and prohibitions of liability set in this
                  Section and elsewhere in this disclaimer: (a) are subject to
                  the preceding paragraph; and (b) govern all liabilities
                  arising under the disclaimer, including liabilities arising in
                  contract, in tort and for breach of statutory duty.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ color: "#666" }}>
                  As long as the website and the information and services on the
                  website are provided free of charge, we will not be liable for
                  any loss or damage of any nature.
                </span>
              </span>
            </span>
          </p>
          <p>
            <br />
            &nbsp;
          </p>
        </div>
      </div>

      <ScrollToTop />
    </>
  );
}
