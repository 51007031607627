import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import checkUserSignedIn from "../util/AuthUtil";
import { auth } from "../config/firebase";
import { signOut } from 'firebase/auth';
import { clearCart } from "../redux/CartSlice";


export default function ProceedToCheckoutView() {
  const { totalItems } = useSelector((state) => state.cart);
  const [authUser, setAuthUser] = useState(null);
  const [showCheckout, setShowCheckout] = useState(false);

  useEffect(() => {
    checkUserSignedIn(auth, setAuthUser);
  }, [authUser]);


  const logout=()=>{
    try{
      signOut(auth)
      }catch(err){
        console.log(err)
      }
      // login()
  }

  return (
    <>
      {authUser && (
        <div className=" fab-container floating_position_left  " style={{zIndex: "100"}}>
          <div
            onClick={logout}
            className="btn px-0 ml-3 fab-item floating_cart fab-item-logout"
            title="logout"
          >
            <img src="../../assets/nt/logout-mobile.svg"></img>
          </div>
        </div>
      )}
      <div className=" fab-container floating_position " style={{zIndex: "100"}}>
        {totalItems > 0 && (
          
          
          // <div style={{ paddingLeft: "20px", paddingRight: "20px",display:"flex" }} className="ml-3">
          //   <button
          //                 className="btn btn-sm btn-danger clear-cart"
          //                 onClick={() => clearCart()}
          //               >
          //     <img src="../../assets/nt/delete1.svg" />
          // </button>
            <Link
              to={"/cart"}
              className="btn px-0 ml-3 fab-item-proceed floating_cart"
            >
              {/* <i className="fas fa-shopping-cart "></i> */}
              Proceed To Checkout
              {totalItems > 0 && (
                <span className="badge  " style={{ paddingBottom: "2px" }}>
                  ({totalItems} Items)
                </span>
              )}
            </Link>
          // </div>
        )}
      </div>
    </>
  );
}
