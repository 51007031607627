import ScrollToTop from "../../components/ScrollToTop";

export default function Privacy() {
  return (
    <>
    
    <p style={{ textAlign: "start" }}>&nbsp;</p>

      <div className="container">
        <div className="policy">
          <p style={{ textAlign: "center" }}>
            <span style={{ fontSize: "17pt" }}>
              <span style={{ fontFamily: "Impact,monospace" }}>
                <span style={{ color: "#000" }}>
                  <strong>
                    <u>PRIVACY POLICY&nbsp;</u>
                  </strong>
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>&nbsp;</p>
          <hr />
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12pt" }}>
              <span style={{ fontFamily: "&quot" }}>
                <span style={{ color: "#000" }}>
                  <strong>
                    <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                      Welcome to{" "}
                    </span>
                  </strong>
                </span>
              </span>
            </span>
            <span style={{ fontSize: "12pt" }}>
              <span style={{ fontFamily: "Lobster,cursive" }}>
                <span
                  style={{
                    color: "#000",
                    fontFamily: "Times New Roman,Times,serif",
                  }}
                >
                  <strong>Nt sticks</strong>
                </span>
              </span>
            </span>
            <span style={{ fontSize: "12pt" }}>
              <span style={{ fontFamily: "&quot" }}>
                <span style={{ color: "#000" }}>
                  <strong>!</strong>
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>&nbsp;</p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  At Ntsticks, we value your privacy and are committed to
                  ensuring that your personal information remains secure. We do
                  not share, rent, or sell our customer base or newsletter
                  emails to any third-party. We also do not store your credit
                  card details on our servers. All credit card transactions are
                  processed through our secure payment gateway for your peace of
                  mind,
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  We collect your personal information when you provide it to us
                  while placing your order. This includes your name, shipping
                  and billing address, credit card number, and email address.
                  This information is used solely to process your order, provide
                  support, and keep you updated on your purchase. We do not
                  share your personal information with any other parties.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  Your email address is only used for our newsletter, which is
                  sent to email addresses that have opted in. You can easily
                  opt-out at any time. If you place an order with us, we will
                  send email messages pertaining to your order but we do not
                  share your email address with other parties.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  We also collect non-personal information such as your browser
                  type, ISP,| operating system, and the URL of the previous
                  website you visited. This information is collected anonymously
                  and is used for analyzing trends, administering the site,
                  tracking user movements, and gathering demographic information
                  about our user base as a whole. We use cookies to provide you
                  with a more personalized user experience, such as keeping
                  items in your shopping cart during your visit.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  For marketing and analysis purposes, we may use selected third
                  parties to collect anonymous information through pixel tags,
                  which is an industry-standard technology used by most major
                  websites. We believe that the use of third-party services like
                  this improves your online experience, as it allows you to see
                  ads that are more relevant to your interests.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  We take the security of your personal information seriously
                  and maintain best practices to protect it, including
                  appropriate physical and electronic security measures. While
                  we have taken all necessary precautions for the protection of
                  personal information, we cannot guarantee complete protection.
                  By providing your personal information, you acknowledge and
                  accept the risks involved.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  Please note that we do not collect or retain credit/debit card
                  information. This information is directly transmitted to our
                  secure third-party payment gateway.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  At Ntsticks, your privacy is of utmost importance to us, and
                  we strive to provide you with a safe and secure online
                  shopping experience.
                </span>
              </span>
            </span>
          </p>
          <h2 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>Changes to this Privacy Policy</strong>
                </span>
              </span>
            </span>
          </h2>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  We may update Our Privacy Policy from time to time. We will
                  notify You of any changes by posting the new Privacy Policy on
                  this page.
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  You are advised to review this Privacy Policy periodically for
                  any changes. Changes to this Privacy Policy are effective when
                  they are posted on this page.
                </span>
              </span>
            </span>
          </p>
          <h2 style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>Contact Us</strong>
                </span>
              </span>
            </span>
          </h2>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  If you have any questions about this Privacy Policy, You can
                  contact us:
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    By email:{" "}
                    <a
                      href= "mailto:ntincensesticks@gmail.com"
                      className="__cf_email__"
                      style={{color:"rgb(0, 0, 238) !important"}}
                    >
                      ntincensesticks@gmail.com
                    </a>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p>
            <br />
            &nbsp;
          </p>{" "}
        </div>
      </div>

      <ScrollToTop />
    </>
  );
}
