import "./css/style.css";
import "./App.css";
import Header from "./common/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Cart,
  Home,
  Shop,
  ProductItem,
  Login,
  Confirmation,
  AdminDashboard,
} from "./pages";
import Footer from "./common/Footer";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth } from "./config/firebase";
import checkUserSignedIn from "./util/AuthUtil";
import { useNavigate } from "react-router-dom";
import ProceedToCheckoutView from "./components/ProceedToCheckoutView";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import Orders from "./pages/Orders/Orders";
import BlogsPage from "./pages/BlogsPage/BlogsPage";
import { Helmet } from "react-helmet";
import Terms from "./pages/Terms/Terms";
import Shipping from "./pages/Shipping/Shipping";
import ReturnRefund from "./pages/ReturnRefund/ReturnRefund";
import Privacy from "./pages/Privacy/Privacy";
import Contact from "./pages/Contact";

function App() {
  const { totalItems } = useSelector((state) => state.cart);
  const [authUser, setAuthUser] = useState(null);
  const [showCheckout, setShowCheckout] = useState(false);
  const { googlePayClient } = window;
  function openCatalogue() {
    window.open("/assets/docs/NTProductCatelogue.pdf");
  }

  useEffect(() => {
    checkUserSignedIn(auth, setAuthUser);
    // React Hook
  }, [authUser, window.location.pathname]);

  // const login = () => {
  //   navigate('/login')

  // };

  return (
    <div>
      <Router>
        <Helmet>
          <title>NT sticks Incense sticks</title>
          <meta
            name="description"
            content="Ignite your senses with NT Sticks"
          />
          <meta
            name="keywords"
            content="Eco-Friendly Ganesh Idol Made of Clay with Free Chouki, Mala, 100g Dhoop Stick, and Red Cloth - Perfect for Ganesh Chaturthi"
          />
        </Helmet>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/item/:id" element={<ProductItem />} />
          <Route path="/*" element={<NotFoundPage />} />
          <Route path="/myorders" element={<Orders />} />
          <Route path="/dashboard" element={<AdminDashboard />} />
          <Route path="/terms" element={<Terms />} />
          <Route exact path="/shipping" element={<Shipping />} />
          <Route exact path="/returnrefund" element={<ReturnRefund />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>

        <Footer />

        <FloatingWhatsApp
          phoneNumber="916264208349"
          accountName="NT STICKS"
          buttonClassName="whatsapp-view"
          notificationSound="true"
          statusMessage="Typically replies within 30 mins"
          allowClickAway="true"
          avatar="/assets/nt/ntsticklogo.png"
          notificationSoundSrc="/assets/nt/sound/notif-sound.mp3"
          notificationDelay="900"
          notificationLoop="5"
        />

        <div className=" ad_container ads_position">
          <span className="btn ad_item floating_cart" onClick={openCatalogue}>
            CATALOGUE
          </span>
        </div>
      </Router>
    </div>
  );
}

export default App;
