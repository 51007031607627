import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import constant from "../../constant/app-constant";
import { useDispatch, useSelector } from "react-redux";
import { storeAccessToken, fetchAccessToken } from "../../redux/CartSlice";
import { redirect, useNavigate } from "react-router-dom";
import { auth, googleProvider } from "../../config/firebase";
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { db } from "../../config/firebase";
import { getDocs,collection } from "firebase/firestore"; 
import checkUserSignedIn from "../../util/AuthUtil";
import ScrollToTop from "../../components/ScrollToTop";
import Heading from "../../common/Heading";


export default function LoginView() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profile, setProfile] = useState(null);
  const [authUser, setAuthUser] = useState(null);
  const navigate = useNavigate();
  const { totalItems } = useSelector((state) => state.cart);


  const productsRef=collection(db,"products")
  // const token = useSelector((state) => state.token);

  // const googleLogin = useGoogleLogin({
  //   onSuccess: (codeResponse) => {
  //     console.log("hgjhjh");
  //     setTokenForStorage(codeResponse);
  //     setUser(codeResponse);
  //   },
  //   onError: (error) => console.log("Login Failed:", error),
  // });


  const googleLogin =async()=> {
    try{
      await signInWithPopup(auth,googleProvider);
      }catch(err){
        console.log(err)
      }
  };
  



  const login = async () => {
    try{
    await createUserWithEmailAndPassword(auth, email, password);
    }catch(err){
      console.log(err)
    }
  };

  function setTokenForStorage(response) {
    console.log("token", response.access_token);
    dispatch(storeAccessToken(response.access_token));
  }

  // const checkUserSignedIn=()=>{
  //   const listen=onAuthStateChanged(auth,(user)=>{
  //     if(user){
  //       setAuthUser(user)
  //     }
  //     else{
  //       setAuthUser(null)
  //     }
  //   })
  //   return ()=> {
  //     listen();
  //   }
  // }

  useEffect(() => {
  checkUserSignedIn(auth,setAuthUser)

    if (user) {
      axios
        .get(`${constant.BASE_URL}userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
  if(authUser)
    totalItems?navigate("/cart"):navigate("/");
},[authUser])

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  return (
    <div>
              <Heading title="Home" subtitle="Login" />
      {/* {<h2>React Google Login</h2>} */}
      <br />
      <br />
      {profile ? (
        <div>
          <img src={profile.picture} alt="user image" />
          <h3>User Logged in</h3>
          <p>Name: {profile.name}</p>
          <p>Email Address: {profile.email}</p>
          <br />
          <br />
          <button onClick={logOut}>Log out</button>
        </div>
      ) : (
        <div>
          <div className="popup-parent">
            <div className="login-popup login-popup-mobile">
              {/* <h2>Welcome to NTSTICKS</h2> */}
              <form>
                {/* <img style={{margin:"10px"}} src="../../assets/nt/patch-check.svg"/> */}
                <label
                  style={{
                    margin: "40px",
                    fontWeight: "700",
                    fontSize: "40px",
                  }}
                >
                  Log In
                </label>
                <br />

              
                <input
                className="form-control border-0 p-4"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  autofocus
                />
                <input
                className="form-control border-0 p-4"
                  placeholder="Password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button className="google-sign-in-button signin-btn" style={{backgroundImage:"unset"}} onClick={() => login()} type="button" >
                  Sign in
                </button>
                <button
                  onClick={() => googleLogin()}
                  type="button"
                  className="google-sign-in-button"
                >
                  Sign in with Google
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      <ScrollToTop/>
    </div>
  );
}
