import React from "react";
import { sidebar } from "../data/Data";
import { products,more_products } from "../data/Data";
import Video from "./Video";


export default function Sidebar(props) {
  const all_products=[...products,...more_products]
  const {onFilterChange}=props

  const filterItems=(evt,filter,typeIndex)=>{
    onFilterChange(evt,filter,typeIndex)
  }

  return (
    <>
      <div className="col-lg-3 col-md-4">
        {sidebar.map((val, index) => (
          <div key={index}>
            <h5 className="section-title position-relative text-uppercase mb-3">
              <span className="bg-secondary pr-3">{val.header}</span>
            </h5>
            <div className="bg-light p-4 mb-30">
              <form>
                {/* <div className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="price-all"
                    onChange={(evt)=>filterItems(evt,val)}
                  />
                  <label className="custom-control-label">{val.all}</label>
                  <span className="badge border font-weight-normal">
                    {all_products.length}
                  </span>
                </div> */}
                {val.subItem.map((item, index) => (
                  <div
                    className="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="price-1"
                      onChange={(evt)=>filterItems(evt,item,val)}
                    />
                    <label className="custom-control-label">
                      {item.variety}
                    </label>
                    <span className="badge border font-weight-normal">
                      {item.quantity}
                    </span>
                  </div>
                ))}
              </form>
            </div>
          </div>
        ))}

        <div className="container-fluid pt-5 pb-3">
        <div className="row ">
        <Video url="../../assets/nt/ntvideo2.mp4" width="100%" height="100%"/>
        </div>
      </div>
      </div>
    </>
  );
}
