import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import emailjs from "emailjs-com";
import { addDoc, collection } from "firebase/firestore";
import { analytics, db, googleProvider } from "../../config/firebase";
import { auth } from "../../config/firebase";
import "./cart.css";


import {
  getCartTotal,
  removeItem,
  updateQuantity,
} from "../../redux/CartSlice";
import Heading from "../../common/Heading";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop";
import { logEvent } from "firebase/analytics";
import { signInWithPopup } from "firebase/auth";
import checkUserSignedIn from "../../util/AuthUtil";

export default function Cart() {
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [pincode, setPincode] = useState("");
  const [fullName, setFullName] = useState("");
  const [showError, setShowError] = useState("");
  const [totalPrice, setTotalPrice] = useState("0");
  const [authUser, setAuthUser] = useState(null);

  const navigate = useNavigate();
  const inputRef = useRef(null);


  const productsRef = collection(db, "orders");
  const [paymentMethod, setPaymentMethod] = useState("");

  const handleOptionChange = (event) => {
    setPaymentMethod(event.target.value);
    setShowError("")
  };

  const increaseQty = (cartProductId, currentQty) => {
    const newQty = currentQty + 1;
    dispatch(updateQuantity({ id: cartProductId, quantity: newQty }));
  };

  const decreaseQty = (cartProductId, currentQty) => {
    const newQty = Math.max(currentQty - 1, 1);
    dispatch(updateQuantity({ id: cartProductId, quantity: newQty }));
  };

  function loginWithGoogleBeforeOrderPlacing() {
    logEvent(analytics, `login for order ${totalAmounts}${fullName} `);

    if (!checkDetails()) return;
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        if (googleProvider) {
          const credential = googleProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          // const user = result.user;
          console.log(credential, "creds");
        }
        placeOrder("api");
        console.log("success");
        logEvent(analytics, `login success ${totalAmounts}${fullName} `);
      })
      .catch((error) => {
        console.log(error);
        placeOrder("api");
        logEvent(analytics, `login failed ${totalAmounts}${fullName} `);

        // ...
      });
  }

  const placeOrder = (typeOfOrder) => {
    if (!checkDetails()) return;
    let charges = parseInt(totalAmounts) > 200 ? deliveryCharge : 50;
    let codCharges = paymentMethod === "Cash on Delivery"?30:0
    const dataToPlaceOrder = {
      totalPrice: totalAmounts + charges+codCharges,
      itemsList: [],
    };
    for (let i = 0; i < cartProducts.length; i++) {
      let obj = {
        itemName: cartProducts[i].product_name_key,
        quantity: cartProducts[i].quantity,
      };
      dataToPlaceOrder["itemsList"].push(obj);
    }
    switch (typeOfOrder) {
      case "api":
        placeOrderViaApi(dataToPlaceOrder);
        break;

      case "whatsapp":
        window.open(
          "https://api.whatsapp.com/send?phone=916264208349" +
            "&text=" +
            encodeURIComponent(JSON.stringify(dataToPlaceOrder)),
          "_blank"
        );
        break;

      case "email":
        {
          emailjs
            .sendForm(
              "service_cott3tr",
              "template_449qdup",
              encodeURIComponent(JSON.stringify(dataToPlaceOrder)),
              "a2RAs1ZB10E12-kyC"
            )
            .then(
              (result) => {},
              (error) => {}
            );
        }
        break;
      case "call":
        document.location.href = "tel:+916264208349";
        break;
    }
  };

  const dispatch = useDispatch();
  const {
    data: cartProducts,
    totalAmounts,
    deliveryCharge,
  } = useSelector((state) => state.cart);

  useEffect(() => {
    logEvent(analytics, `User reached cart ${totalAmounts}`);
    checkUserSignedIn(auth, setAuthUser);
    setTotalPrice(String(totalAmounts + deliveryCharge));
    dispatch(getCartTotal());
  }, [useSelector((state) => state.cart)]);

  const handleRemoveItem = (itemId) => {
    dispatch(removeItem({ id: itemId }));
  };

  function setRefOnField(){
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus on the input element
    }
  }

  function checkDetails() {
    if (!address || !phone || !fullName || !pincode) {
      setShowError("Complete the neccessary details");
      setRefOnField()
      return;
    } else {
      if(!paymentMethod){
        setShowError("Please select delivery type")
        setRefOnField()
        return;
      }
      setShowError("")
      return true;
    }
  }

  const placeOrderViaApi = async (data) => {
    if (!checkDetails()) {
      logEvent(
        analytics,
        `validation failed for order ${totalAmounts}${fullName} `
      );
      return;
    }
    let res;
    logEvent(analytics, `order placed ${totalAmounts}${fullName} `);
    try {
      res = await addDoc(productsRef, {
        Address: address,
        email: auth?.currentUser?.email,
        customerName: fullName,
        pincode: pincode,
        phone: phone,
        isRepeat: false,
        orderData: data,
        promocode: "",
      });
    } catch (err) {
      console.log(err);
    }
    console.log("place order response", res);
    navigate("/confirmation");
  };

  const emptyCartMsg = (
    <h4 className="container text-center p-4">Your Cart is Empty</h4>
  );

  return (
    <>
      <Heading title="Home" subtitle="Cart" />
      {cartProducts.length === 0 ? (
        emptyCartMsg
      ) : (
        <div className="container-fluid">
          <div className="row px-xl-5">
            <div className="col-lg-10 table-responsive mb-5">
              <table className="table table-light table-borderless table-hover text-center mb-0">
                <thead className="thead-dark">
                  <tr>
                    <th>Products</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody className="align-middle">
                  {cartProducts.map((cartProduct, i) => (
                    <tr>
                      <td className="align-middle">
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "auto" }}>
                            <img
                              src={cartProduct.product_img}
                              alt="img"
                              style={{ width: "50px" }}
                              key={i}
                            />{" "}
                          </div>
                          <label style={{ width: "70%", margin: "auto" }}>
                            {cartProduct.product_name}
                          </label>
                        </div>
                      </td>
                      <td className="align-middle">{cartProduct.price}</td>
                      <td className="align-middle">
                        <div
                          className="input-group quantity mx-auto"
                          style={{ width: "100px" }}
                        >
                          <div className="input-group-btn">
                            <button
                              className="btn btn-sm btn-primary btn-minus"
                              onClick={() =>
                                decreaseQty(
                                  cartProduct.id,
                                  cartProduct.quantity
                                )
                              }
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                          </div>
                          <input
                            type="text"
                            className="form-control form-control-sm bg-secondary border-0 text-center"
                            value={cartProduct.quantity || 1}
                          />
                          <div className="input-group-btn">
                            <button
                              className="btn btn-sm btn-primary btn-plus"
                              onClick={() =>
                                increaseQty(
                                  cartProduct.id,
                                  cartProduct.quantity
                                )
                              }
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">{cartProduct.totalPrice}</td>
                      <td className="align-middle">
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleRemoveItem(cartProduct.id)}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <form className="mb-15" action="">
                {/* <div className="input-group">
                  <input
                    type="text"
                    className="form-control border-0 p-4"
                    placeholder="Coupon Code"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary">Apply Coupon</button>
                  </div>
                </div> */}
                <div className="input-group" style={{ marginTop: "20px" }}>
                  <input
                    type="text"
                    className="form-control border-0 p-4"
                    placeholder="Delivery Address "
                    onChange={(e) => setAddress(e.target.value)}
                    ref={inputRef}
                  />
                  <input
                    type="number"
                    className="form-control border-0 p-4"
                    placeholder=" Pincode"
                    style={{ marginLeft: "10px" }}
                    onChange={(e) => setPincode(e.target.value)}

                  />
                  <div className="input-group-append">
                    {/* <button
                      className="btn btn-primary"
                      type="button"
                      onClick={setAddressSubmit}
                    >
                      Submit
                    </button> */}
                  </div>
                </div>
                <div style={{ marginTop: "20px", display: "flex" }}>
                  <input
                    type="text"
                    className="form-control border-0 p-4"
                    placeholder="Full Name"
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  <input
                    type="number"
                    className="form-control border-0 p-4"
                    style={{ marginLeft: "10px" }}
                    placeholder="Phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="cart_page mt-20">
                  <div className=" radio-group  ">
                  <label
                      className={`radio-label ${
                        paymentMethod === "Prepayment" ? "selected" : ""
                      }`}
                    >
                      <input
                      
                        type="radio"
                        value="Prepayment"
                        checked={paymentMethod === "Prepayment"}
                        onChange={handleOptionChange}
                        className="radio-input"
                      />
                      Pre Payment
                    </label>
                    <label
                      className={`radio-label ${
                        paymentMethod === "Cash on Delivery" ? "selected" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        value="Cash on Delivery"
                        checked={paymentMethod === "Cash on Delivery"}
                        onChange={handleOptionChange}
                        className="radio-input"
                      />
                      Cash on Delivery<span style={{marginLeft:"6px",fontSize:"x-small"}}>(+30₹)</span>
                    </label>
                    
                  </div>
                </div>
              </form>
              {showError && (
                <label style={{ color: "red" }}>
                  {showError}
                </label>
              )}
              <h5 className="section-title position-relative text-uppercase mb-3 mt-20">
                <span className="bg-secondary pr-3">Cart Summary</span>
              </h5>
              <label>
                {" "}
                {address && (
                  <span>
                    We will deliver here - {address}
                    {" ,"}
                    {pincode}
                  </span>
                )}{" "}
              </label>
              <div className="bg-light p-30 mb-5">
                <div className="border-bottom pb-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h6>Subtotal</h6>
                    <h6>₹ {totalAmounts}.00</h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h6 className="font-weight-medium">Shipping</h6>
                    <h6 className="font-weight-medium">
                      ₹ {parseInt(totalAmounts) > 200 ? deliveryCharge : "50"}
                      .00
                    </h6>
                  </div>
                  {paymentMethod === "Cash on Delivery"&&<div className="d-flex justify-content-between">
                    <h6 className="font-weight-medium">COD Charges</h6>
                    <h6 className="font-weight-medium">
                      ₹ 30.00
                    </h6>
                  </div>}
                </div>
                <div className="pt-2">
                  <div className="d-flex justify-content-between mt-2">
                    <h5>Total</h5>
                    <h5>
                      ₹{" "}
                      {totalAmounts +
                        (parseInt(totalAmounts) > 200 ? deliveryCharge : 50)+(paymentMethod === "Cash on Delivery"?30:0)}
                      .00
                    </h5>
                  </div>
                  {/* <div
                    onClick={processPayment}
                    dangerouslySetInnerHTML={{ __html: gPayBtn && gPayBtn.innerHTML }}
                    /> */}
                  <button
                    onClick={() =>
                      authUser
                        ? placeOrder("api")
                        : loginWithGoogleBeforeOrderPlacing()
                    }
                    className="btn btn-block btn-primary font-weight-bold my-3 py-3"
                  >
                    Place Order
                  </button>
                  <div style={{ display: "flex" }}>
                    <button
                      onClick={() => placeOrder("whatsapp")}
                      style={{ marginRight: "6px" }}
                      className="btn btn-block btn-primary font-weight-bold my-3 py-3"
                    >
                      Place Order via Whatsapp
                    </button>
                    {/* <button
                      onClick={() => placeOrder("email")}
                      className="btn btn-block btn-primary font-weight-bold my-3 py-3"
                    >
                      Place Order via Email
                    </button> */}
                    <button
                      onClick={() => placeOrder("call")}
                      className="btn btn-block btn-primary font-weight-bold my-3 py-3"
                    >
                      Place Order via Call
                    </button>
                  </div>

                  {/* <StripeCheckout
                    token={onToken}
                    stripeKey={publishableKey}
                    amount={100 * totalAmounts}
                    name="MD"
                    currency="USD"
                    label="Proceed Checkout"
                    className="btn btn-block btn-primary font-weight-bold my-3 py-3"
                  /> */}
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className=" paytm-parent">
                <img
                  className="paytm-view"
                  src="../../assets/nt/paytm.png"
                ></img>
              </div>
            </div>
          </div>
        </div>
      )}
      <ScrollToTop />
      {/* <RenderScript resourceUrl={gpayScript} onGooglePayLoaded={onGooglePayLoaded} /> */}
    </>
  );
}
