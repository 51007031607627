import { useEffect } from "react";
import Orders from "../Orders/Orders";
import whitelistedUser from "../../constant/whitelisted-user";
import { auth } from "../../config/firebase";

export default function AllOrders() {
  useEffect(() => {
    if (
      auth &&
      auth.currentUser &&
      !whitelistedUser.includes(auth.currentUser.email)
    )
      return;
  }, []);
  return <>{auth && auth.currentUser && <Orders allOrders />}</>;
}
