import { Helmet } from "react-helmet";
import Blogs from "../../components/Blogs";
import { blog_desc, blog_title, blogs } from "../../data/Data";


export default function BlogsPage(){


    return(
        <>
        <Helmet>
          <title>{blog_title}</title>
          <meta name="description" content={blog_desc} />
        </Helmet>
        <Blogs blogsData={blogs} isBlogsPage/>
        
        </>

    )
}