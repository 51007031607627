import React from "react";
import { footer, footerTouch, nav, socialIcon } from "../data/Data";
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com'
import { useState } from 'react'


const initialState = {
  name: '',
  email: '',
  message: '',
}
export default function Footer() {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  function onContactClick(type){

    switch(type){
      
      case "call":
        document.location.href = "tel:+916264208349";
        break;

        case "email":
          document.location.href= "mailto:ntincensesticks@gmail.com"
        break

        case "address":
          window.open("https://maps.app.goo.gl/KbZvmANdAmZXx9E29",'_blank')
        break


    }

  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)
    emailjs
      .sendForm(
        'service_cott3tr', 'template_449qdup', e.target, 'a2RAs1ZB10E12-kyC'
      )
      .then(
        (result) => {
          console.log(result.text)
          clearState()
          document.getElementById("sentMessage").reset();
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <>
      <div className="container-fluid bg-dark text-secondary mt-5 pt-5">
        <div className="row px-xl-5 pt-5">
          <div className="col-lg-8 col-md-12 mb-5 pr-3 pr-xl-5">
            <h5 className="text-secondary text-uppercase mb-4">Get In Touch</h5>
            <p className="mb-4">
            Ntstick.com is a store bringing to you the best fragrance products which resembles all natural pure fragrances. Discover an array of handpicked products and accessories associated with prayer requirements, air care and lifestyle products.
            </p>
            {footerTouch.map((val, index) => (
              <div className="mb-2" key={index}>
                {val.icon}
                <a onClick={()=>onContactClick(val.type)}>{val.contact}</a>
              </div>
            ))}
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="row">
              {/* {footer.map((val, index) => (
                <div className="col-md-3 mb-5" key={index} style={{visibility: "hidden" }}>
                  <h5 className="text-secondary text-uppercase mb-4">
                    {val.header}
                  </h5>
                  <div className="d-flex flex-column justify-content-start">
                    {nav.map((val, item) => (
                      <Link className="text-secondary mb-2" href="#" key={item}>
                        <i className="fa fa-angle-right mr-2"></i>
                        {val.text}
                      </Link>
                    ))}
                  </div>
                </div>
              ))} */}

              <div className="col-md-12 mb-5" style={{float: "right"}}>
                {/* <h5 className="text-secondary text-uppercase mb-4">
                  Newsletter
                </h5> */}
                {/* <p>Duo stet tempor ipsum sit amet magna ipsum tempor est</p> */}
                {/* <form name='sentMessage' id="sentMessage" validate onSubmit={handleSubmit}>
                  <div className="input-group">
                    <input
                      type='text'
                      id='name'
                      name='name'
                      className='form-control'
                      placeholder='Name'
                      required
                      onChange={handleChange}
                    />
                    <input
                       type='email'
                       id='email'
                       name='email'
                       className='form-control '
                       placeholder='Email'
                       required
                       style={{marginLeft:'2px'}}
                       onChange={handleChange}
                    /><br/>
                    </div>
                    <div>
                    <textarea
                      name='message'
                      id='message'
                      className='form-control form_margin'
                      rows='4'
                      placeholder='Message'
                      required
                      onChange={handleChange}
                    />

                    <div className="input-group-append">
                      <button className="btn btn-primary">Get In Touch</button>
                    </div>
                  </div>
                </form> */}
                <h6 className="text-secondary text-uppercase mt-4 mb-3">
                  Follow Us
                </h6>
                <div className="d-flex">
                  {socialIcon.map((icon, index) => (
                    <a
                      className="btn btn-primary btn-square mr-2"
                      href={icon.link}
                      key={index}
                      target="_blank"
                    >
                      {icon.icon}
                    </a>
                  ))}
                </div>
                <div style={{marginTop:"20px"}}>
                <Link to={"/privacy"}>Privacy Policy</Link><br/>
                <Link to={"/returnrefund"}>Return And Refund Policy</Link><br/>
                <Link to={"/shipping"}>Shipping Policy</Link><br/>
                <Link to={"/terms"}>Terms And Conditions</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className=" border-top mx-xl-5 py-4"
          style={{ borderColor: "rgba(256, 256, 256, 0.1) !important" }}
        >
          <div className=" px-xl-0">
            <p className="mb-md-0 text-center text-secondary">
              &copy;{" "}
              All Rights Reserved by{" "}
              <a
                className="text-primary"
                href="https://www.instagram.com/nt_incense_sticks?igsh=dWpibWZsZ3ZpaXMz"
              >
                ACHHARI PERFUMERY WORKS
              </a>
            </p>
          </div>
          {/* <div className="col-md-6 px-xl-0 text-center text-md-right">
            <img className="img-fluid" src="img/payments.png" alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
}
