import React from "react";
import { banner } from "../data/Data";
import Video from "./Video";

export default function Offers() {
  return (
    <>
    <div className="flexdisplay">
      <div className="container-fluid pt-5 pb-3">
        <div className=" px-xl-5">
        <Video className="showInMobile" url="../../assets/nt/ntvideo.mp4" width="100%" height="100%"/>
        <div>
        <Video className="showInMobileYoutube" url="https://youtu.be/Lo4bwSwLizA" width="100%" height="100%"/>
        <h6>Note: Full Screen Recommended</h6>
        </div>
        </div>
      </div>
      {/* <div className="container-fluid pt-5 pb-3">
        <div className="row px-xl-5">
        <Video url="../../assets/nt/ntvideo2.mp4"/>
        </div>
      </div> */}
      </div>
    </>
  );
}
