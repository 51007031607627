import { useEffect, useState } from "react";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";
import { auth } from "../../config/firebase";
import "./orders.css";
import Heading from "../../common/Heading";

export default function Orders(props) {
  const [ordersList, setOrdersList] = useState([]);
  const {allOrders}=props


  useEffect(() => {
    
    const getOrderList = async () => {
    let ordersRef
    const email= auth?.currentUser?.email
    if(!allOrders&&email){
     ordersRef = query(collection(db, "orders"), where("email", "==", email));
    }else{
     ordersRef = (collection(db, "orders"));
    }
      try {
        const data = await getDocs(ordersRef);
        let filteredData = data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        if(!allOrders){
        filteredData = filteredData.filter((item, index) => {
          return item.email == auth.currentUser.email;
        });
        }
        console.log(filteredData);
        setOrdersList(filteredData);
      } catch (err) {
        console.log(err);
      }
    };
    getOrderList();
  }, []);

  return (<>
    <Heading title="Home" subtitle={!allOrders?"My Orders":"Dashboard"} />

    <div style={{ textAlign: "center" }}>
      {ordersList && ordersList.length ? (
        <div className="orders_body row-orders">
          {ordersList.map((item,i) => (
            <div class="card " key={i}>
              <div class="card-image"></div>
              <div class="card-title" style={{display:"flex"}}>
                <span style={{display: "flex"}}>
                <label style={{width: "max-content"}}>OrderId :</label> <span>#{item.id} </span>
                </span>
                <span className="amount">
                <label >₹ {item.orderData&&item.orderData.totalPrice}</label>  
                </span>
              </div>
              <div class="card-subtitle">
                <label>Address : </label>
                 {item.Address}
              </div>
              <div class="card-text">
                <label>Phone : </label>
                {item.phone}
              </div>
              <div class="card-text">
                <label>Email : </label>
                {item.email}
              </div>
              <div class="card-text">
                <label>Orders :-</label>
              </div>
              <table>
                <thead>
                <tr>
                  <th>Quantity</th>
                  <th>ItemName</th>
                </tr>
                </thead>
                <tbody>
                {item.orderData &&
                  item.orderData.itemsList &&
                  item.orderData.itemsList.map((orderItem,index) => (
                    <tr key={index}>
                      <td>{orderItem.quantity}</td>
                      <td>{orderItem.itemName}</td>
                    </tr>
                  ))}
                  </tbody>
              </table>
            </div>
          ))}
        </div>
      ) : (
        "No Order Found"
      )}
    </div>
    </>
  );
}
