import React, { useState } from "react";
import "./contact.css";
import { footerTouch, socialIcon } from "../../data/Data";
import emailjs from "emailjs-com";


const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log(formData)
    emailjs
            .sendForm(
              "service_cott3tr",
              "template_449qdup",
              '#queryForm',
              "a2RAs1ZB10E12-kyC"
            )
            .then(
              (result) => {},
              (error) => {}
            );
  };

  return (
    <div className="contact-us-container">
      <div className="hero-section">
        <h1>Get in Touch</h1>
        <p>We would love to hear from you!</p>
      </div>

      <form id="queryForm" className="contact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <select
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
        >
          <option value="" disabled>
            Select a Subject
          </option>
          <option value="Support">Support</option>
          <option value="Sales">Sales</option>
          <option value="Feedback">Feedback</option>
        </select>
        <textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <button type="submit">Send Message</button>
      </form>

      <div className="company-info">
        <h2>Contact Information</h2>
        <p>
          <i className="fas fa-map-marker-alt"></i>
          {footerTouch[0].contact}{" "}
        </p>
        <p>
          <i className="fas fa-phone"></i>
          {footerTouch[2].contact}
        </p>
        <p>
          <i className="fas fa-envelope"></i> {footerTouch[1].contact}
        </p>
      </div>

      {/* <div className="social-media">
      <div className="d-flex">
        {socialIcon.map((icon, index) => (
          <a
            className="btn btn-square mr-2"
            href={icon.link}
            key={index}
            target="_blank"
          >
            {icon.icon}
          </a>
        ))}
        </div>
      </div> */}
    </div>
  );
};

export default ContactUs;
