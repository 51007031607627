export const category = [
  {
    id: 1,
    category_name: "Incense Sticks",
    subCat: [
      {
        id: 11,
        category_name: "Zipper",
      },
      {
        id: 12,
        category_name: "Bundle 250 gm",
      },
      {
        id: 13,
        category_name: "Box",
      },
      {
        id: 14,
        category_name: "Jar 100gm",
      },
      {
        id: 15,
        category_name: "Jar 250gm",
      },
    ],
    quantity: "23 Fragrances Available",
    img: "../assets/nt/aggr-sticks.jpeg",
  },
  {
    id: 2,
    category_name: "Dhoop Sticks",
    subCat: [
      {
        id: 21,
        category_name: "Jar",
      },
      {
        id: 12,
        category_name: "Box",
      },
    ],
    quantity: "23 Flavors Available",
    img: "../assets/nt/6.jpg",
  },
  {
    id: 3,
    category_name: "Dhoop Cone",
    quantity: "13 Fragrances Available",
    img: "../assets/nt/7.jpg",
  },
  {
    id: 4,
    category_name: "Sambrani Cup",
    quantity: "Havan Fragrance Available",
    subCat: [
      {
        id: 41,
        category_name: " 10pc Box",
      },
      {
        id: 42,
        category_name: "12pc Box",
      },
      {
        id: 41,
        category_name: " 25pc Jar",
      },
    ],
    img: "../assets/nt/sambharani.png",
  }
];

export const blog_title="Celebrate with Color: The Rise of Eco-friendly Ganesh Idols for Ganesh Chaturthi"
export const blog_desc="Explore the significance of color in Ganesh Chaturthi celebrations and discover NT Sticks eco-friendly Ganesh idols made from clay, available in vibrant colors, and accompanied by a free Choki."

export const nav = [
  {
    path: "/",
    text: "Home",
  },
  {
    path: "/shop",
    text: "Shop",
  },
  {
    path: "/about",
    text: "About",
  },
  {
    path: "/contact",
    text: "Contact",
  },
  {
    path: "/help",
    text: "Help",
  },
  {
    path: "/faqs",
    text: "FAQs",
  },
];

export const topdropdown = [
  {
    id: 1,
    btn: "My Account",
  },

  {
    id: 3,
    btn: "USD",
  },
  {
    id: 2,
    btn: "EN",
  },
];
export const blogs_show_more = [
  {
    id:1,
    que: "Why Eco-Friendly Ganesh Idols Are Important for Ganesh Chaturthi ?",
    ans: "Ganesh Chaturthi, one of India's most beloved festivals, gathers communities together to commemorate the birth of Lord Ganesha, ",
  },

  {
    id:2,
    que: "The Environmental Impact of Traditional Ganesh Idols",
    ans: "Traditionally, plaster of Paris (PoP), which is not only non-biodegradable but also detrimental to aquatic life, ",
  },
  {
    id:3,
    que: "Cultural Significance Meets Environmental Responsibility",
    ans: "Selecting an eco-friendly Ganesh idol is a method to respect Lord Ganesha's very nature as well as demonstrate environmental responsibility.",
  },
  {
    id:4,
    que:"How You Can Make a Difference This Ganesh Chaturthi",
    ans:"As we prepare for Ganesh Chaturthi, let’s consider the impact of our choices. Opting for an eco-friendly Ganesh idol is a simple yet powerful way to reduce our environmental footprint When you choose an idol made from natural materials, "

  }
];
export const blogs = [
  {
    id:1,
    que: "Why Eco-Friendly Ganesh Idols Are Important for Ganesh Chaturthi ?",
    ans: "Ganesh Chaturthi, one of India's most beloved festivals, gathers communities together to commemorate the birth of Lord Ganesha, the remover of obstacles and the bringer of fresh beginnings. The festival's core is embodied in the colorful processions, devotional singing, and the installation of exquisitely made idols. The effects of these festivities on the environment have, however, come under investigation recently. Because Ganesh idols made of non-biodegradable materials have sparked worries about pollution, there is a rising push to replace them with environmentally friendly options.",
  },

  {
    id:2,
    que: "The Environmental Impact of Traditional Ganesh Idols",
    ans: "Traditionally, plaster of Paris (PoP), which is not only non-biodegradable but also detrimental to aquatic life, has been used to make Ganesh idols. At the conclusion (Ganpati Visarjan) of the celebration, these Ganesh idols are submerged in water, but they don't dissolve quickly, which pollutes the water. Heavy elements like lead and mercury are present in the chemical paints used to decorate these idols, further poisoning the water and endangering marine ecosystems.<br /> On the other hand, natural clay, paper mache, or other biodegradable materials are used to create eco-friendly Ganesh idols. These idols don't hurt the environment because they dissolve quickly in water. Additionally, natural colors are a safer option for the environment and the marine life who utilize them because they ensure that no harmful elements are emitted during the visarjan.",
  },
  {
    id:3,
    que: "Cultural Significance Meets Environmental Responsibility",
    ans: "Selecting an eco-friendly Ganesh idol is a method to respect Lord Ganesha's very nature as well as demonstrate environmental responsibility. The wisdom god Ganesha would undoubtedly support initiatives aimed at safeguarding and maintaining the environment that provides us with life. We connect our festivities with the principles of reverence and regard for all living things by adopting eco-friendly techniques.<br /> The trend for eco-friendly idols also gives an opportunity to help local artists who craft these idols by hand with traditional techniques that have been handed down through the years. This preserves the festival's cultural legacy in addition to being the helping hand to support their livelihood",
  },
  {
    id:4,
    que:"How You Can Make a Difference This Ganesh Chaturthi",
    ans:"As we prepare for Ganesh Chaturthi, let’s consider the impact of our choices. Opting for an eco-friendly Ganesh idol is a simple yet powerful way to reduce our environmental footprint When you choose an idol made from natural materials, you contribute to a cleaner environment and a more sustainable future.<br /> But why stop at just the idol? This year, you can take your commitment to the environment a step further with NT Sticks’ specially curated Eco-friendly Ganesh Kit. Our kit includes everything you need for a 10-day Ganesh Pooja, thoughtfully designed to minimize environmental impact. The kit contains an eco-friendly Ganesh idol, Sambrani cups, Dhoop sticks, Chooki, Red Cloth, Kalash, and Pooja Samagri—all made with the highest quality, natural ingredients.<br /> By choosing our Ganesh Kit, you not only simplify your preparations but also ensure that your celebration is in harmony with nature. Let’s celebrate Ganesh Chaturthi with a sense of responsibility and devotion, knowing that we are leaving a positive legacy for future generations. Embrace eco-friendly practices, support sustainable products, and let the blessings of Lord Ganesha guide us towards a greener, more mindful world."

  },
  {
    id:5,
    que: "Celebrating Ganesh Chaturthi with a Splash of Color: The Rise of Eco-Friendly Ganesh Idols",
    ans: "Ganesh Chaturthi, a festival that fills homes with joy, devotion, and color, brings families together to celebrate Lord Ganesha, the deity of wisdom and prosperity. Traditionally, this celebration is marked by the installation of a beautifully crafted Ganesh idol, adorned in vibrant colors and surrounded by flower, sweet, and incense offerings. However, as people become more aware of environmental issues, they are looking for new ways to celebrate this festival that honors both tradition and nature. This has led to a rise in the popularity of eco-friendly Ganesh idols, which not only uphold the spiritual essence of the festival but also contribute to a healthier planet.",
  },

  {
    id:6,
    que: "The Significance of Color in Ganesh Chaturthi Celebrations",
    ans: "An essential part of Indian philosophy and culture are colours. Every colour has a unique symbolic meaning and energy that affects the ambience of the area where the idol is placed. For example, the colour red stands for strength and power, and the colour yellow for study and knowledge—qualities that are intimately related to Lord Ganesha. In addition to adding to the celebrations' aesthetic appeal, a brightly coloured Ganesh idol also corresponds with the spiritual vibrations you are trying to activate. The deliberate use of chemical paints to Ganesh idols has historically presented a serious environmental risk. These paints frequently include dangerous ingredients that, when dissolved in water, can pollute the water, endangering ecosystems and aquatic life. Natural clay Ganesh idols that are dyed with organic colours provide a colourful, sustainable, and environmentally friendly substitute.",
  },
  {
    id:7,
    que: "Why Choose an Eco-friendly Ganesh Idol?",
    ans: "Eco-friendly Natural clay, which is both biodegradable and has great spiritual importance, is used to manufacture Ganesh idols. By representing the Earth itself, clay grounds the idol in the natural world. These idols disintegrate readily in water, leaving no pollution residue behind. By doing this, you can celebrate Ganesh Chaturthi in a way that respects the environment and expresses your devotion without sacrificing your moral principles. Additionally, purchasing an eco-friendly idol helps support regional craftspeople who create these idols by hand using age-old techniques. Your purchase supports the continuation of these cultural traditions, as idol-making has been a family craft in many of the areas from which these artists hail.",
  },
  {
    id:8,
    que:"Adding a Personalized Touch to Your Celebrations",
    ans:"The option to select from a range of colours that speak to your own sense of spirituality and style is what distinguishes eco-friendly Ganesh idols. There is an eco-friendly Ganesh idol to fit every taste, whether you like the energetic feelings of red and yellow or the soothing tones of blue and green. This gives you the opportunity to personalize your Ganesh Chaturthi celebration and make it a representation of your distinct commitment and flair.<br/> We at NT Sticks are pleased to provide an assortment of Eco-friendly Ganesh Idols, which are made from natural clay and come in a variety of hues. A free Choki is included with every idol, which will add an extra touch of tradition to your holiday preparations. Selecting one of our environmentally friendly idols will not only make your house look better, but it will also help to protect the environment.<br/> Make the decision to celebrate Ganesh Chaturthi with an <a style='color: rgb(72, 225, 72)' href='/item/Eco_Friendly_Ganesh_Kit'>eco-friendly Ganesh idol</a> this year. Allow your love to be shown in eco-friendly hues, and allow Lord Ganesha's blessings to lead you to a more peaceful and sustainable way of life."

  }


];
export const socialIcon = [
  {
    icon: <i className="fab fa-instagram"></i>,
    name: "instagram",
    link: "https://www.instagram.com/ntsticks",
  },
  {
    icon: <i className="fab fa-facebook-f"></i>,
    name: "facebook",
    link:"https://www.facebook.com/people/NT-Sticks/61562741386886/"
  },
  {
    icon: <i class="fab fa-amazon"></i>,
    name: "Amazon",
    link:"https://www.amazon.in/s?k=ACHHARI PERFUMERY WORKS"

  },
  {
    icon: <i className="fab fa-youtube"></i>,
    name: "youtube",
    link: "https://www.youtube.com/@ntsticks",
  },
  
];


export const ganesh={
    id: 0,
    product_name:"Diwali Hamper",
    product_name_key:"Diwali_Hamper",
    product_name_detail: "Diwali Hamper",
      product_img: "../assets/nt/diwali-complete-kit.jpeg",
      price: "1699",
      discountPer: "-33.33%",
      mainPrice: "1999",
      weight: "1500",
      description:
        "The Diwali hamper is a beautifully curated collection of items that combine festive decor, health-conscious snacks, chocolates, and relaxing elements like incense and candles. It’s designed to bring together the spirit of Diwali through decor, treats, and meaningful gifting, offering both indulgence and celebration essentials.",
      stars: Array(5).fill(
        <small className="fa fa-star text-primary mr-1"></small>
      ),
      ratingCount: 99,
      meta_title:"Complete Diwali Hamper",
      meta_description:"The Diwali hamper is a beautifully curated collection of items that combine festive decor, health-conscious snacks, chocolates, and relaxing elements like incense and candles. It’s designed to bring together the spirit of Diwali through decor, treats, and meaningful gifting, offering both indulgence and celebration essentials.",
      gallery: [
        // {
        //   id: 0,
        //   url_s: "../assets/nt/ganesh.svg",
        //   url_l: "../assets/nt/ganesh.svg",
        // },
        // {
        //   id: 1,
        //   url_s: "../assets/nt/ganesh1.png",
        //   url_l: "../assets/nt/ganesh1.png",
        // },
        // {
        //   id: 2,
        //   url_s: "../assets/nt/ganesh2.png",
        //   url_l: "../assets/nt/ganesh2.png",
        // },
        // // {
        // //   id: 3,
        // //   url_s: "../assets/nt/ganesh3.png",
        // //   url_l: "../assets/nt/ganesh3.png",
        // // },
        // {
        //   id: 3,
        //   url_s: "../assets/nt/ganesh4.png",
        //   url_l: "../assets/nt/ganesh4.png",
        // },
       
      ]
    
}
export const products = [
  {
    id: 0,
    product_name:"Diwali Hamper",
    product_name_key:"Diwali_Hamper",
    product_name_detail: "Diwali Hamper",
      product_img: "../assets/nt/diwali-complete-kit.jpeg",
      price: "1699",
      discountPer: "-33.33%",
      mainPrice: "1999",
      weight: "1500",
      description:
        "The Diwali hamper is a beautifully curated collection of items that combine festive decor, health-conscious snacks, chocolates, and relaxing elements like incense and candles. It’s designed to bring together the spirit of Diwali through decor, treats, and meaningful gifting, offering both indulgence and celebration essentials.",
      stars: Array(5).fill(
        <small className="fa fa-star text-primary mr-1"></small>
      ),
      ratingCount: 99,
      meta_title:"Complete Diwali Hamper",
      meta_description:"The Diwali hamper is a beautifully curated collection of items that combine festive decor, health-conscious snacks, chocolates, and relaxing elements like incense and candles. It’s designed to bring together the spirit of Diwali through decor, treats, and meaningful gifting, offering both indulgence and celebration essentials.",
      gallery: [
        // {
        //   id: 0,
        //   url_s: "../assets/nt/ganesh.svg",
        //   url_l: "../assets/nt/ganesh.svg",
        // },
        // {
        //   id: 1,
        //   url_s: "../assets/nt/ganesh1.png",
        //   url_l: "../assets/nt/ganesh1.png",
        // },
        // {
        //   id: 2,
        //   url_s: "../assets/nt/ganesh2.png",
        //   url_l: "../assets/nt/ganesh2.png",
        // },
        // // {
        // //   id: 3,
        // //   url_s: "../assets/nt/ganesh3.png",
        // //   url_l: "../assets/nt/ganesh3.png",
        // // },
        // {
        //   id: 3,
        //   url_s: "../assets/nt/ganesh4.png",
        //   url_l: "../assets/nt/ganesh4.png",
        // },
       
      ],
  },
  {
    id: 1,
    product_name:"Eco-Friendly Ganesh Ji",
    product_name_key:"Eco_Friendly_Ganesh_Ji_N5",
    product_name_detail: " 6 Inch Eco-Friendly Ganesh Idol Made of Clay with Free Chouki - Perfect for Ganesh Chaturthi",
      product_img: "../assets/idols/g1/0.png",
      price: "299",
      discountPer: "-33.33%",
      mainPrice: "699",
      weight: "500",
      description:
        "Celebrate the auspicious festival of Ganesh Chaturthi with our eco-friendly Ganesh idol, crafted with love and respect for nature. These Ganesh clay idols are made from natural, biodegradable materials like clay, ensuring minimal harm to the environment. By choosing an eco-friendly Ganesh idol, you not only honor Lord Ganesha but also contribute to preserving our planet for future generations.These idols are designed with intricate details, capturing the essence of Indian culture and tradition. Whether you plan to immerse the idol in water or perform an eco-friendly immersion at home, our eco-friendly Ganpati idols are a sustainable choice that aligns with the principles of Dharma. Embrace the joy of the festival while being mindful of our planet's well-being.Bring home an eco-friendly Ganesh idol and experience the divine blessings of the remover of obstacles while doing your part for the environment. Celebrate with our eco-friendly Ganesh Chaturthi idols and enjoy a greener, more responsible festival.",
      stars: Array(5).fill(
        <small className="fa fa-star text-primary mr-1"></small>
      ),
      ratingCount: 99,
      meta_title:" Eco-Friendly Ganesh Ji for Pooja",
      meta_description:"Celebrate Ganesh Chaturthi sustainably with an eco-friendly Ganesh idol  with Free Chouki.",
      gallery: [
        {
          id: 0,
          url_s: "../assets/idols/g1/1.png",
          url_l: "../assets/idols/g1/1.png",
        },
        {
          id: 1,
          url_s: "../assets/idols/g1/2.png",
          url_l: "../assets/idols/g1/2.png",
        },
        {
          id: 2,
          url_s: "../assets/idols/g1/3.png",
          url_l: "../assets/idols/g1/3.png",
        },
        {
          id: 3,
          url_s: "../assets/idols/g1/4.png",
          url_l: "../assets/idols/g1/4.png",
        },
       
      ],
  },
  {
    id: 2,
    product_name:"Eco-Friendly Ganesh Ji",
    product_name_key:"Eco_Friendly_Ganesh_Ji_C1",
    product_name_detail: " 6 Inch Eco-Friendly Ganesh Idol Made of Clay with Free Chouki - Perfect for Ganesh Chaturthi",
      product_img: "../assets/idols/g2/0.png",
      price: "299",
      discountPer: "-33.33%",
      mainPrice: "699",
      weight: "500",
      description:
        "Celebrate the auspicious festival of Ganesh Chaturthi with our eco-friendly Ganesh idol, crafted with love and respect for nature. These Ganesh clay idols are made from natural, biodegradable materials like clay, ensuring minimal harm to the environment. By choosing an eco-friendly Ganesh idol, you not only honor Lord Ganesha but also contribute to preserving our planet for future generations.These idols are designed with intricate details, capturing the essence of Indian culture and tradition. Whether you plan to immerse the idol in water or perform an eco-friendly immersion at home, our eco-friendly Ganpati idols are a sustainable choice that aligns with the principles of Dharma. Embrace the joy of the festival while being mindful of our planet's well-being.Bring home an eco-friendly Ganesh idol and experience the divine blessings of the remover of obstacles while doing your part for the environment. Celebrate with our eco-friendly Ganesh Chaturthi idols and enjoy a greener, more responsible festival.",
      stars: Array(5).fill(
        <small className="fa fa-star text-primary mr-1"></small>
      ),
      ratingCount: 99,
      meta_title:" Eco-Friendly Ganesh Ji for Pooja",
      meta_description:"Celebrate Ganesh Chaturthi sustainably with an eco-friendly Ganesh idol  with Free Chouki.",
      gallery: [
        {
          id: 0,
          url_s: "../assets/idols/g2/0.png",
          url_l: "../assets/idols/g2/0.png",
        },
        {
          id: 1,
          url_s: "../assets/idols/g2/1.png",
          url_l: "../assets/idols/g2/1.png",
        },
        {
          id: 2,
          url_s: "../assets/idols/g2/2.png",
          url_l: "../assets/idols/g2/2.png",
        },
        {
          id: 3,
          url_s: "../assets/idols/g2/3.png",
          url_l: "../assets/idols/g2/3.png",
        },
       
      ],
  },
  {
    id: 3,
    product_name:"Eco-Friendly Ganesh Ji",
    product_name_key:"Eco_Friendly_Ganesh_Ji_N3",
    product_name_detail: " 6 Inch Eco-Friendly Ganesh Idol Made of Clay with Free Chouki - Perfect for Ganesh Chaturthi",
      product_img: "../assets/idols/g3/0.png",
      price: "299",
      discountPer: "-33.33%",
      mainPrice: "699",
      weight: "500",
      description:
        "Celebrate the auspicious festival of Ganesh Chaturthi with our eco-friendly Ganesh idol, crafted with love and respect for nature. These Ganesh clay idols are made from natural, biodegradable materials like clay, ensuring minimal harm to the environment. By choosing an eco-friendly Ganesh idol, you not only honor Lord Ganesha but also contribute to preserving our planet for future generations.These idols are designed with intricate details, capturing the essence of Indian culture and tradition. Whether you plan to immerse the idol in water or perform an eco-friendly immersion at home, our eco-friendly Ganpati idols are a sustainable choice that aligns with the principles of Dharma. Embrace the joy of the festival while being mindful of our planet's well-being.Bring home an eco-friendly Ganesh idol and experience the divine blessings of the remover of obstacles while doing your part for the environment. Celebrate with our eco-friendly Ganesh Chaturthi idols and enjoy a greener, more responsible festival.",
      stars: Array(5).fill(
        <small className="fa fa-star text-primary mr-1"></small>
      ),
      ratingCount: 99,
      meta_title:" Eco-Friendly Ganesh Ji for Pooja",
      meta_description:"Celebrate Ganesh Chaturthi sustainably with an eco-friendly Ganesh idol  with Free Chouki.",
      gallery: [
        {
          id: 0,
          url_s: "../assets/idols/g3/0.png",
          url_l: "../assets/idols/g3/0.png",
        },
        {
          id: 1,
          url_s: "../assets/idols/g3/1.png",
          url_l: "../assets/idols/g3/1.png",
        },
        {
          id: 2,
          url_s: "../assets/idols/g3/2.png",
          url_l: "../assets/idols/g3/2.png",
        },
        {
          id: 3,
          url_s: "../assets/idols/g3/3.png",
          url_l: "../assets/idols/g3/3.png",
        },
       
      ],
  },
  {
    id: 4,
    product_name:"Eco-Friendly Ganesh Ji",
    product_name_key:"Eco_Friendly_Ganesh_Ji_N1",
    out_of_stock:true,
    product_name_detail: " 6 Inch Eco-Friendly Ganesh Idol Made of Clay with Free Chouki - Perfect for Ganesh Chaturthi",
      product_img: "../assets/idols/g4/0.png",
      price: "299",
      discountPer: "-33.33%",
      mainPrice: "699",
      weight: "500",
      description:
        "Celebrate the auspicious festival of Ganesh Chaturthi with our eco-friendly Ganesh idol, crafted with love and respect for nature. These Ganesh clay idols are made from natural, biodegradable materials like clay, ensuring minimal harm to the environment. By choosing an eco-friendly Ganesh idol, you not only honor Lord Ganesha but also contribute to preserving our planet for future generations.These idols are designed with intricate details, capturing the essence of Indian culture and tradition. Whether you plan to immerse the idol in water or perform an eco-friendly immersion at home, our eco-friendly Ganpati idols are a sustainable choice that aligns with the principles of Dharma. Embrace the joy of the festival while being mindful of our planet's well-being.Bring home an eco-friendly Ganesh idol and experience the divine blessings of the remover of obstacles while doing your part for the environment. Celebrate with our eco-friendly Ganesh Chaturthi idols and enjoy a greener, more responsible festival.",
      stars: Array(5).fill(
        <small className="fa fa-star text-primary mr-1"></small>
      ),
      ratingCount: 99,
      meta_title:" Eco-Friendly Ganesh Ji for Pooja",
      meta_description:"Celebrate Ganesh Chaturthi sustainably with an eco-friendly Ganesh idol  with Free Chouki.",
      gallery: [
        {
          id: 0,
          url_s: "../assets/idols/g4/0.png",
          url_l: "../assets/idols/g4/0.png",
        },
        {
          id: 1,
          url_s: "../assets/idols/g4/1.png",
          url_l: "../assets/idols/g4/1.png",
        },
        {
          id: 2,
          url_s: "../assets/idols/g4/2.png",
          url_l: "../assets/idols/g4/2.png",
        },
        {
          id: 3,
          url_s: "../assets/idols/g4/3.png",
          url_l: "../assets/idols/g4/3.png",
        },
       
      ],
  },
  {
    id: 5,
    product_name:"Eco-Friendly Ganesh Ji",
    product_name_key:"Eco_Friendly_Ganesh_Ji_N2P",
    product_name_detail: " 6 Inch Eco-Friendly Ganesh Idol Made of Clay with Free Chouki - Perfect for Ganesh Chaturthi",
      product_img: "../assets/idols/g5/0.png",
      price: "299",
      discountPer: "-33.33%",
      mainPrice: "699",
      weight: "500",
      description:
        "Celebrate the auspicious festival of Ganesh Chaturthi with our eco-friendly Ganesh idol, crafted with love and respect for nature. These Ganesh clay idols are made from natural, biodegradable materials like clay, ensuring minimal harm to the environment. By choosing an eco-friendly Ganesh idol, you not only honor Lord Ganesha but also contribute to preserving our planet for future generations.These idols are designed with intricate details, capturing the essence of Indian culture and tradition. Whether you plan to immerse the idol in water or perform an eco-friendly immersion at home, our eco-friendly Ganpati idols are a sustainable choice that aligns with the principles of Dharma. Embrace the joy of the festival while being mindful of our planet's well-being.Bring home an eco-friendly Ganesh idol and experience the divine blessings of the remover of obstacles while doing your part for the environment. Celebrate with our eco-friendly Ganesh Chaturthi idols and enjoy a greener, more responsible festival.",
      stars: Array(5).fill(
        <small className="fa fa-star text-primary mr-1"></small>
      ),
      ratingCount: 99,
      meta_title:" Eco-Friendly Ganesh Ji for Pooja",
      meta_description:"Celebrate Ganesh Chaturthi sustainably with an eco-friendly Ganesh idol  with Free Chouki.",
      gallery: [
        {
          id: 0,
          url_s: "../assets/idols/g5/0.png",
          url_l: "../assets/idols/g5/0.png",
        },
        {
          id: 0,
          url_s: "../assets/idols/g5/1.png",
          url_l: "../assets/idols/g5/1.png",
        },
        {
          id: 1,
          url_s: "../assets/idols/g5/2.png",
          url_l: "../assets/idols/g5/2.png",
        },
        {
          id: 2,
          url_s: "../assets/idols/g5/3.png",
          url_l: "../assets/idols/g5/3.png",
        },
        {
          id: 3,
          url_s: "../assets/idols/g5/4.png",
          url_l: "../assets/idols/g5/4.png",
        },
       
      ],
  },
  {
    id: 6,
    product_name:"Eco-Friendly Ganesh Ji",
    product_name_key:"Eco_Friendly_Ganesh_Ji_C3",
    product_name_detail: " 6 Inch Eco-Friendly Ganesh Idol Made of Clay with Free Chouki - Perfect for Ganesh Chaturthi",
      product_img: "../assets/idols/g6/0.png",
      price: "299",
      discountPer: "-33.33%",
      mainPrice: "699",
      weight: "500",
      description:
        "Celebrate the auspicious festival of Ganesh Chaturthi with our eco-friendly Ganesh idol, crafted with love and respect for nature. These Ganesh clay idols are made from natural, biodegradable materials like clay, ensuring minimal harm to the environment. By choosing an eco-friendly Ganesh idol, you not only honor Lord Ganesha but also contribute to preserving our planet for future generations.These idols are designed with intricate details, capturing the essence of Indian culture and tradition. Whether you plan to immerse the idol in water or perform an eco-friendly immersion at home, our eco-friendly Ganpati idols are a sustainable choice that aligns with the principles of Dharma. Embrace the joy of the festival while being mindful of our planet's well-being.Bring home an eco-friendly Ganesh idol and experience the divine blessings of the remover of obstacles while doing your part for the environment. Celebrate with our eco-friendly Ganesh Chaturthi idols and enjoy a greener, more responsible festival.",
      stars: Array(5).fill(
        <small className="fa fa-star text-primary mr-1"></small>
      ),
      ratingCount: 99,
      meta_title:" Eco-Friendly Ganesh Ji for Pooja",
      meta_description:"Celebrate Ganesh Chaturthi sustainably with an eco-friendly Ganesh idol  with Free Chouki.",
      gallery: [
        {
          id: 0,
          url_s: "../assets/idols/g6/0.png",
          url_l: "../assets/idols/g6/0.png",
        },
        {
          id: 0,
          url_s: "../assets/idols/g6/1.png",
          url_l: "../assets/idols/g6/1.png",
        },
        {
          id: 1,
          url_s: "../assets/idols/g6/2.png",
          url_l: "../assets/idols/g6/2.png",
        },
        {
          id: 2,
          url_s: "../assets/idols/g6/3.png",
          url_l: "../assets/idols/g6/3.png",
        },
        {
          id: 3,
          url_s: "../assets/idols/g6/4.png",
          url_l: "../assets/idols/g6/4.png",
        },
       
      ],
  },
  {
    id: 7,
    product_name:"Eco-Friendly Ganesh Ji",
    product_name_key:"Eco_Friendly_Ganesh_Ji_C2",
    product_name_detail: " 6 Inch Eco-Friendly Ganesh Idol Made of Clay with Free Chouki - Perfect for Ganesh Chaturthi",
      product_img: "../assets/idols/g7/0.png",
      price: "299",
      discountPer: "-33.33%",
      mainPrice: "699",
      weight: "500",
      description:
        "Celebrate the auspicious festival of Ganesh Chaturthi with our eco-friendly Ganesh idol, crafted with love and respect for nature. These Ganesh clay idols are made from natural, biodegradable materials like clay, ensuring minimal harm to the environment. By choosing an eco-friendly Ganesh idol, you not only honor Lord Ganesha but also contribute to preserving our planet for future generations.These idols are designed with intricate details, capturing the essence of Indian culture and tradition. Whether you plan to immerse the idol in water or perform an eco-friendly immersion at home, our eco-friendly Ganpati idols are a sustainable choice that aligns with the principles of Dharma. Embrace the joy of the festival while being mindful of our planet's well-being.Bring home an eco-friendly Ganesh idol and experience the divine blessings of the remover of obstacles while doing your part for the environment. Celebrate with our eco-friendly Ganesh Chaturthi idols and enjoy a greener, more responsible festival.",
      stars: Array(5).fill(
        <small className="fa fa-star text-primary mr-1"></small>
      ),
      ratingCount: 99,
      meta_title:" Eco-Friendly Ganesh Ji for Pooja",
      meta_description:"Celebrate Ganesh Chaturthi sustainably with an eco-friendly Ganesh idol  with Free Chouki.",
      gallery: [
        {
          id: 0,
          url_s: "../assets/idols/g7/0.png",
          url_l: "../assets/idols/g7/0.png",
        },
        {
          id: 0,
          url_s: "../assets/idols/g7/1.png",
          url_l: "../assets/idols/g7/1.png",
        },
        {
          id: 1,
          url_s: "../assets/idols/g7/2.png",
          url_l: "../assets/idols/g7/2.png",
        },
        {
          id: 2,
          url_s: "../assets/idols/g7/3.png",
          url_l: "../assets/idols/g7/3.png",
        },
        {
          id: 3,
          url_s: "../assets/idols/g7/4.png",
          url_l: "../assets/idols/g7/4.png",
        },
        {
          id: 4,
          url_s: "../assets/idols/g7/5.png",
          url_l: "../assets/idols/g7/5.png",
        },

       
      ],
  },
  {
    id: 8,
    product_name:"Eco-Friendly Ganesh Ji",
    product_name_key:"Eco_Friendly_Ganesh_Ji_N2M",
    product_name_detail: " 6 Inch Eco-Friendly Ganesh Idol Made of Clay with Free Chouki - Perfect for Ganesh Chaturthi",
      product_img: "../assets/idols/g8/0.png",
      price: "299",
      discountPer: "-33.33%",
      mainPrice: "699",
      weight: "500",
      description:
        "Celebrate the auspicious festival of Ganesh Chaturthi with our eco-friendly Ganesh idol, crafted with love and respect for nature. These Ganesh clay idols are made from natural, biodegradable materials like clay, ensuring minimal harm to the environment. By choosing an eco-friendly Ganesh idol, you not only honor Lord Ganesha but also contribute to preserving our planet for future generations.These idols are designed with intricate details, capturing the essence of Indian culture and tradition. Whether you plan to immerse the idol in water or perform an eco-friendly immersion at home, our eco-friendly Ganpati idols are a sustainable choice that aligns with the principles of Dharma. Embrace the joy of the festival while being mindful of our planet's well-being.Bring home an eco-friendly Ganesh idol and experience the divine blessings of the remover of obstacles while doing your part for the environment. Celebrate with our eco-friendly Ganesh Chaturthi idols and enjoy a greener, more responsible festival.",
      stars: Array(5).fill(
        <small className="fa fa-star text-primary mr-1"></small>
      ),
      ratingCount: 99,
      meta_title:" Eco-Friendly Ganesh Ji for Pooja",
      meta_description:"Celebrate Ganesh Chaturthi sustainably with an eco-friendly Ganesh idol  with Free Chouki.",
      gallery: [
        {
          id: 0,
          url_s: "../assets/idols/g8/0.png",
          url_l: "../assets/idols/g8/0.png",
        },
        {
          id: 0,
          url_s: "../assets/idols/g8/1.png",
          url_l: "../assets/idols/g8/1.png",
        },
        {
          id: 1,
          url_s: "../assets/idols/g8/2.png",
          url_l: "../assets/idols/g8/2.png",
        },
        {
          id: 2,
          url_s: "../assets/idols/g8/3.png",
          url_l: "../assets/idols/g8/3.png",
        },
        {
          id: 3,
          url_s: "../assets/idols/g8/4.png",
          url_l: "../assets/idols/g8/4.png",
        },
        {
          id: 3,
          url_s: "../assets/idols/g8/5.png",
          url_l: "../assets/idols/g8/5.png",
        },
        {
          id: 3,
          url_s: "../assets/idols/g8/6.png",
          url_l: "../assets/idols/g8/6.png",
        },
       
      ],
  },
  

  {
    id: 9,
    product_name: "Sandal Jar",
    product_name_key:"Sandal_Jar",
    product_img: "../assets/nt/jar/1.jpeg",
    price: "55",
    mainPrice: "80",
    description:
      "Sandal Fragrance Dhoop Sticks bring the sacred and timeless aroma of sandalwood into your home, offering a fragrance that is both calming and spiritually uplifting. Sandalwood has been revered for centuries in religious and spiritual practices for its ability to center the mind and evoke a sense of peace. Our dhoop sticks are made from pure sandalwood, ensuring a consistent burn that releases the deep, woody scent of sandalwood into your space. This soothing aroma is perfect for meditation, prayer, or creating a tranquil environment where you can unwind and connect with your inner self. The fragrance of Sandal Dhoop Sticks is grounding and comforting, helping to purify your space and elevate your spiritual practices. Whether you are seeking to enhance your meditation or simply enjoy the sacred scent of sandalwood, these dhoop sticks provide a rich olfactory experience that nurtures the soul and fosters a peaceful atmosphere.",

    weight: "100",
    meta_title:"Sandal Dhoop Sticks - Sacred Sandalwood Aroma",
    meta_description:"Experience the sacred scent of Sandal Dhoop Sticks, crafted with sandalwood for a calming and spiritual atmosphere.",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/jar/1.jpeg",
        url_l: "../assets/nt/jar/1.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/1.jpeg",
        url_l: "../assets/nt/jar/1.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/1.jpeg",
        url_l: "../assets/nt/jar/1.jpeg",
      },
    ],
  },
  {
    id: 10,
    product_name: "White Musk Jar",
    product_name_key:"White_Musk_Jar",
    product_img: "../assets/nt/jar/white-musk.jpeg",
    price: "55",
    mainPrice: "80",
    description:
      "Indulge in the serene and luxurious aroma of White Musk Fragrance Dhoop Sticks, an exquisite blend of natural ingredients designed to elevate your spiritual and meditation practices. The rich, smooth fragrance of white musk envelops your senses, creating a tranquil ambiance perfect for relaxation or sacred rituals. Our dhoop sticks are crafted using traditional methods, ensuring that each stick burns evenly, releasing a long-lasting scent that lingers in your space. Whether you’re seeking peace after a long day or aiming to enhance your spiritual practices, White Musk Dhoop Sticks offer a unique olfactory experience that connects you with nature and divinity. Perfect for use during meditation, yoga, or simply to refresh your living space, these dhoop sticks provide an aromatic journey that soothes the mind and spirit.",

    weight: "100",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"White Musk Dhoop Sticks - Pure Fragrance Bliss",
    meta_description:"Experience the soothing aroma of White Musk Dhoop Sticks, crafted with natural ingredients for a serene and calming environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/jar/white-musk.jpeg",
        url_l: "../assets/nt/jar/white-musk.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/white-musk.jpeg",
        url_l: "../assets/nt/jar/white-musk.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/white-musk.jpeg",
        url_l: "../assets/nt/jar/white-musk.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/white-musk.jpeg",
        url_l: "../assets/nt/jar/white-musk.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/white-musk.jpeg",
        url_l: "../assets/nt/jar/white-musk.jpeg",
      },
    ],
  },
  {
    id: 11,
    product_name: "Hawan Jar",
    product_name_key:"Hawan_Jar",
    product_img: "../assets/nt/jar/3.jpeg",
    price: "55",
    mainPrice: "80",
    description:"Hawan Fragrance Dhoop Sticks are crafted to bring the divine essence of traditional Hindu rituals into your home. Infused with sacred herbs and resins, these dhoop sticks evoke the sanctity of a hawan, a fire ritual meant to purify the environment and invite positive energies. Each stick is meticulously prepared to ensure a slow, even burn, releasing a rich and potent aroma that purifies the air and uplifts your spirit. Ideal for religious ceremonies, meditation, or daily prayers, Hawan Dhoop Sticks create a sacred space where you can connect with the divine. The earthy and soothing fragrance calms the mind, making it easier to focus and meditate. Whether you're conducting a full ritual or simply wishing to cleanse your space, these dhoop sticks offer an authentic experience of spiritual purity and tranquility.",
    weight: "100",
    meta_title:"Hawan Dhoop Sticks - Sacred Aroma for Rituals",
    meta_description:"Elevate your rituals with Hawan Dhoop Sticks, infused with sacred herbs and ingredients for a divine and purifying fragrance.",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/jar/3.jpeg",
        url_l: "../assets/nt/jar/3.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/3.jpeg",
        url_l: "../assets/nt/jar/3.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/3.jpeg",
        url_l: "../assets/nt/jar/3.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/3.jpeg",
        url_l: "../assets/nt/jar/3.jpeg",
      },
      
    ],
  }
];

export const more_products = [
  {
    id: 12,
    product_name: "Loban Jar",
    product_name_key:"Loban_Jar",
    product_img: "../assets/nt/jar/4.jpeg",
    price: "55",
    mainPrice: "80",
    description:"Loban Fragrance Dhoop Sticks bring the ancient purifying aroma of loban, a resin derived from the Styrax tree, into your living spaces. Known for its grounding and cleansing properties, loban has been used in traditional rituals for centuries to purify the environment and promote tranquility. Our dhoop sticks are crafted with precision, ensuring an even burn that releases the rich, balsamic scent of loban throughout your home. This soothing aroma not only purifies the air but also creates a peaceful atmosphere, making it ideal for meditation, yoga, or simply unwinding after a long day. Whether you’re looking to cleanse your space of negative energy or enhance your spiritual practices, Loban Dhoop Sticks provide a sensory experience that calms the mind and uplifts the spirit. Let the sacred fragrance of loban transform your home into a sanctuary of peace and relaxation.",

    weight: "100",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Loban Dhoop Sticks - Purify & Soothe Your Space",
    meta_description:"Purify your home with Loban Dhoop Sticks, offering a grounding and soothing fragrance ideal for meditation and spiritual cleansing.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/jar/4.jpeg",
        url_l: "../assets/nt/jar/4.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/4.jpeg",
        url_l: "../assets/nt/jar/4.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/4.jpeg",
        url_l: "../assets/nt/jar/4.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/4.jpeg",
        url_l: "../assets/nt/jar/4.jpeg",
      },
      
    ],
  },
  {
    id: 13,
    product_name: "Muskan Box",
    product_name_key:"Muskan_Box",
    product_img: "../assets/nt/muskanmock.png",
    price: "300",
    mainPrice: "350",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "1000",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Muskan Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Muskan Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
    ],
  },

  {
    id: 14,
    product_name: "Kasturi",
    product_name_key:"Kasturi",
    product_img: "../assets/nt/kasturi.jpg",
    price: "55",
    mainPrice: "80",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "100",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Kasturi Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Kasturi Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/kasturi.jpg",
        url_l: "../assets/nt/kasturi.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/kasturi.jpg",
        url_l: "../assets/nt/kasturi.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/kasturi.jpg",
        url_l: "../assets/nt/kasturi.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/kasturi.jpg",
        url_l: "../assets/nt/kasturi.jpg",
      },
    ],
  },

  {
    id: 15,
    product_name: "Super Mogra box",
    product_name_key:"Super_Mogra_box",
    product_img: "../assets/nt/sm_box.png",
    price: "10",
    mainPrice: "15",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "100",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Super Mogra Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Super Mogra Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
    ],
  },

  {
    id: 16,
    product_name: "Muskan",
    product_name_key:"Muskan",
    product_img: "../assets/nt/jar/5.jpeg",
    price: "55",
    mainPrice: "80",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "100",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Muskan Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Muskan Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
    ],
  },
  {
    id: 17,
    product_name: "Oudh box",
    product_name_key:"Oudh_box",
    product_img: "../assets/nt/oudh_jar.jpg",
    price: "55",
    mainPrice: "80",
    weight: "100",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Oudh Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Oudh Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
    ],
  },

  {
    id: 18,
    product_name: "Lavender",
    product_name_key:"Lavender",
    product_img: "../assets/nt/lavender.jpg",
    price: "55",
    mainPrice: "85",
    description:"Lavender Fragrance Dhoop Sticks are your gateway to tranquility, offering the soothing and calming essence of lavender in every burn. Lavender, known for its stress-relieving properties, has been a staple in aromatherapy for centuries, valued for its ability to calm the mind and body. Our dhoop sticks are meticulously crafted with natural ingredients, ensuring a pure and long-lasting fragrance that gently infuses your space with peace and serenity. As the lavender scent fills your home, it creates a tranquil atmosphere that helps you unwind and relax, making it ideal for use before bedtime, during meditation, or whenever you need a moment of calm. The gentle, floral aroma of lavender not only soothes the senses but also purifies the environment, making these dhoop sticks a perfect addition to your daily rituals. Embrace the calming power of lavender with these premium dhoop sticks and transform your space into a sanctuary of peace and relaxation.",
    weight: "200",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Lavender Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Lavender Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/lavender.jpg",
        url_l: "../assets/nt/lavender.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/lavender.jpg",
        url_l: "../assets/nt/lavender.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/lavender.jpg",
        url_l: "../assets/nt/lavender.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/lavender.jpg",
        url_l: "../assets/nt/lavender.jpg",
      },
    ],
  },
  {
    id: 19,
    product_name: "Rajnigandha",
    product_name_key:"Rajnigandha",
    product_img: "../assets/nt/rajnigandha.jpg",
    price: "55",
    mainPrice: "80",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "200",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Rajnigandha Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Rajnigandha Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/rajnigandha.jpg",
        url_l: "../assets/nt/rajnigandha.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/rajnigandha.jpg",
        url_l: "../assets/nt/rajnigandha.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/rajnigandha.jpg",
        url_l: "../assets/nt/rajnigandha.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/rajnigandha.jpg",
        url_l: "../assets/nt/rajnigandha.jpg",
      },
    ],
  },
  {
    id: 20,
    product_name: "Mogra Super",
    product_name_key:"Mogra_Super",
    product_img: "../assets/nt/mogra_super.jpg",
    price: "55",
    mainPrice: "85",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "200",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Mogra Super Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Mogra Super Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/mogra_super.jpg",
        url_l: "../assets/nt/mogra_super.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/mogra_super.jpg",
        url_l: "../assets/nt/mogra_super.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/mogra_super.jpg",
        url_l: "../assets/nt/mogra_super.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/mogra_super.jpg",
        url_l: "../assets/nt/mogra_super.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/mogra_super.jpg",
        url_l: "../assets/nt/mogra_super.jpg",
      },
      
    ],
  },

  {
    id: 21,
    product_name: "White Gulab Jar",
    product_name_key:"White_Gulab_Jar",
    product_img: "../assets/nt/white_gulab_jar.jpg",
    price: "55",
    mainPrice: "80",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "100",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"White Gulab Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with White Gulab Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
    ],
  },
  {
    id: 22,
    product_name: "White Musk Jar",
    product_name_key:"White_Musk_Jar",
    product_img: "../assets/nt/jar/white-musk.jpeg",
    price: "55",
    mainPrice: "80",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "100",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"White Musk Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with White Musk Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/jar/white-musk.jpeg",
        url_l: "../assets/nt/jar/white-musk.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/white-musk.jpeg",
        url_l: "../assets/nt/jar/white-musk.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/white-musk.jpeg",
        url_l: "../assets/nt/jar/white-musk.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/white-musk.jpeg",
        url_l: "../assets/nt/jar/white-musk.jpeg",
      },
     
    ],
  },
  {
    id: 23,
    product_name: "Hawan Jar",
    product_name_key:"Hawan_Jar",
    product_img: "../assets/nt/chameli_jar.jpg",
    price: "55",
    mainPrice: "80",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "100",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Hawan Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Hawan Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
    ],
  },
  {
    id: 24,
    product_name: "Guggal Jar",
    product_name_key:"Guggal_Jar",
    product_img: "../assets/nt/guggal.jpg",
    price: "55",
    mainPrice: "80",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "100",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Guggal Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Guggal Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/6flavors.jpg",
        url_l: "../assets/nt/6flavors.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/guggal.jpg",
        url_l: "../assets/nt/guggal.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/guggal.jpg",
        url_l: "../assets/nt/guggal.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/guggal.jpg",
        url_l: "../assets/nt/guggal.jpg",
      }
    ],
  },
  {
    id: 25,
    product_name: "Kewda",
    product_name_key:"Kewda",
    product_img: "../assets/nt/kewda.jpg",
    price: "55",
    mainPrice: "80",
    weight: "100",
    description:
      "Kewda Fragrance Dhoop Sticks bring the fresh and invigorating scent of the kewda flower into your home, creating an atmosphere of purity and tranquility. Kewda, known for its unique and uplifting fragrance, has been used in traditional rituals and spiritual practices for centuries. Our dhoop sticks are crafted from natural ingredients, ensuring a smooth and even burn that releases the full essence of kewda throughout your space. This refreshing floral scent is perfect for meditation, prayer, or simply enhancing the ambiance of your living environment. The aroma of kewda is known to rejuvenate the senses and uplift the spirit, making it ideal for creating a serene and peaceful atmosphere. Whether you are deepening your spiritual practice or seeking a moment of relaxation, Kewda Dhoop Sticks provide a sensory experience that connects you with the essence of nature and enhances your overall well-being.",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Kewda Dhoop Sticks - Refreshing Floral Essence",
    meta_description:"Elevate your space with Kewda Dhoop Sticks, offering a refreshing and invigorating floral fragrance ideal for relaxation and spiritual practices.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/kewda.jpg",
        url_l: "../assets/nt/kewda.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/kewda.jpg",
        url_l: "../assets/nt/kewda.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/kewda.jpg",
        url_l: "../assets/nt/kewda.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/kewda.jpg",
        url_l: "../assets/nt/kewda.jpg",
      }
    ],
  },

  {
    id: 26,
    product_name: "Ruh Heena",
    product_name_key:"Ruh_Heena",
    product_img: "../assets/nt/jar/ruh-heena.jpeg",
    price: "55",
    mainPrice: "80",
    description:"Ruh Heena Fragrance Dhoop Sticks capture the rich and earthy aroma of henna, also known as mehandi, offering an exotic fragrance that connects you with ancient traditions. Henna has been cherished for its aromatic and therapeutic properties, often used in spiritual rituals and ceremonies. Our dhoop sticks are made from natural ingredients, providing a long-lasting and consistent burn that releases the deep, warm scent of henna throughout your space. This grounding fragrance is ideal for meditation, relaxation, and creating a peaceful environment in your home. The aroma of Ruh Heena Dhoop Sticks evokes a sense of comfort and tranquility, making it perfect for enhancing your spiritual practices or simply unwinding after a busy day. Let the exotic fragrance of henna envelop your senses, bringing you closer to nature and enriching your spiritual journey.",
    weight: "100",
    meta_title:"Ruh Heena Dhoop Sticks - Exotic Henna Aroma",
    meta_description:"Immerse in the exotic scent of Ruh Heena Dhoop Sticks, offering a warm and earthy fragrance perfect for spiritual and relaxation practices.",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/ruh-heena.jpeg",
        url_l: "../assets/nt/ruh-heena.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/ruh-heena.jpeg",
        url_l: "../assets/nt/ruh-heena.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/ruh-heena.jpeg",
        url_l: "../assets/nt/ruh-heena.jpeg",
      },
    ],
  },

  {
    id: 27,
    product_name: "Muskan",
    product_name_key:"Muskan",
    product_img: "../assets/nt/jar/5.jpeg",
    price: "55",
    mainPrice: "80",
    description:"Muskan Fragrance Dhoop Sticks are designed to bring joy and positivity into your life with their uplifting floral aroma. 'Muskan' means smile in Hindi, and these dhoop sticks truly live up to their name by filling your space with a delightful fragrance that brightens the atmosphere and lifts your spirits. Made from natural ingredients, these dhoop sticks burn evenly and consistently, releasing a vibrant and cheerful scent that can enhance any room. The fresh floral aroma of Muskan is perfect for starting your day on a positive note, for use during meditation, or for creating a warm and welcoming environment for guests. Whether you're looking to boost your mood or simply enjoy the beauty of this delightful fragrance, Muskan Dhoop Sticks offer a sensory experience that brings happiness and serenity to your home.",
    weight: "100",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Muskan Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Muskan Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/jar/5.jpeg",
        url_l: "../assets/nt/jar/5.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/5.jpeg",
        url_l: "../assets/nt/jar/5.jpeg",
      },
      {
        id: 0,
        url_s: "../assets/nt/jar/5.jpeg",
        url_l: "../assets/nt/jar/5.jpeg",
      }
     
    ],
  },
  {
    id: 28,
    product_name: "Firangipani box",
    product_name_key:"Firangipani_box",
    product_img: "../assets/nt/mockups/fp.png",
    price: "10",
    mainPrice: "15",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "50",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Firangipani Dhoop Sticks - Exotic Floral Bliss",
    meta_description:"Immerse in the exotic fragrance of Firangipani Dhoop Sticks, a floral delight that enhances relaxation and spiritual ambiance.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/mockups/fp.png",
        url_l: "../assets/nt/mockups/fp.png",
      },
      {
        id: 1,
        url_s: "../assets/nt/mockups/fp.png",
        url_l: "../assets/nt/mockups/fp.png",
      },
      {
        id: 2,
        url_s: "../assets/nt/mockups/fp.png",
        url_l: "../assets/nt/mockups/fp.png",
      },
     
    ],
  },

  {
    id: 29,
    product_name: "Rose Zipper",
    product_name_key:"Rose_Zipper",
    product_img: "../assets/nt/3.jpg",
    price: "55",
    mainPrice: "85",
    description:"Rose Fragrance Dhoop Sticks are crafted to bring the timeless and luxurious scent of roses into your living spaces. The rich and romantic aroma of roses has been cherished for centuries, symbolizing love, beauty, and serenity. Our dhoop sticks capture the essence of fresh roses, providing a fragrance that is both uplifting and calming. Whether you're setting the mood for a special occasion, enhancing your meditation practice, or simply enjoying a moment of tranquility, Rose Dhoop Sticks offer a sensory experience that soothes the soul and pleases the senses. Each stick is carefully made to ensure an even burn, allowing the full-bodied fragrance to permeate your space and linger long after the stick has finished burning. Let the enchanting scent of roses transform your home into a haven of peace and romance, where every breath is a reminder of the beauty and elegance that surrounds you.",
    weight: "200",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Rose Dhoop Sticks - Luxurious Floral Aroma",
    meta_description:"Indulge in the luxurious scent of Rose Dhoop Sticks, perfect for creating a romantic and peaceful ambiance in your home or sacred space.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/3.jpg",
        url_l: "../assets/nt/3.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/3.jpg",
        url_l: "../assets/nt/3.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/3.jpg",
        url_l: "../assets/nt/3.jpg",
      }
    ],
  },
  {
    id: 30,
    product_name: "Muskaan Zipper",
    product_name_key:"Muskaan_Zipper",
    product_img: "../assets/nt/4.jpg",
    price: "55",
    mainPrice: "80",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "200",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Muskan Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Muskan Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/4.jpg",
        url_l: "../assets/nt/4.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/4.jpg",
        url_l: "../assets/nt/4.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/4.jpg",
        url_l: "../assets/nt/4.jpg",
      }
    ],
  },
  {
    id: 31,
    product_name: "3 in 1 Zipper",
    product_name_key:"3_in_1_Zipper",
    product_img: "../assets/nt/5.jpg",
    price: "55",
    mainPrice: "85",
    description:
      "Inspired by Beauty, Heritage Radiant Rose Agarbatti bring the Enticing, Lovely Fragrance of rose blossoms that will uplift your passionate senses. No other fragrance in the world is as soothing, sedative and indescribably sweet and alluring as that of a Rose. It’s refreshing aroma and therapeutic properties instills happiness and contentment all around",

    weight: "200",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
    meta_title:"Muskan Dhoop Sticks - Uplifting Floral Delight",
    meta_description:"Brighten your day with Muskan Dhoop Sticks, offering a joyful and uplifting floral fragrance perfect for enhancing your mood and environment.",
    gallery: [
      {
        id: 0,
        url_s: "../assets/nt/5.jpg",
        url_l: "../assets/nt/5.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/5.jpg",
        url_l: "../assets/nt/5.jpg",
      },
      {
        id: 0,
        url_s: "../assets/nt/5.jpg",
        url_l: "../assets/nt/5.jpg",
      }
    ],
  },

];

export const banner = [
  {
    title: "Special Offer",
    discount: "save 20%",
    img: "../assets/img/offer-1.jpg",
    btn: "Shop Now",
  },
  {
    title: "Special Offer",
    discount: "save 20%",
    img: "../assets/img/offer-2.jpg",
    btn: "Shop Now",
  },
];
export const features = [
  {
    id: 0,
    // icon: <h1 style={{color:"#48e148 !important"}} className="fa fa-phone-volume text-primary m-0 mr-3 dark-greeen"></h1>,
    icon:<img className="cod-style" src="../assets/nt/cod.png"></img>,
    title: "Pay On Delivery",
  },
  {
    id: 1,
    icon: <h1 className="fa fa-check text-primary m-0 mr-3 dark-greeen"></h1>,
    title: "Quality Products",
  },
  {
    id: 2,
    icon: <h1 style={{color:"#48e148 !important"}} className="fa fa-shipping-fast text-primary m-0 mr-2 dark-greeen"></h1>,
    title: "Free Shipping",
  },
  {
    id: 3,
    icon: <h1 style={{color:"#48e148 !important"}} className="fas fa-exchange-alt text-primary m-0 mr-3 dark-greeen"></h1>,
    title: "7-Days Return",
  },
  // {
  //   id: 4,
  //   icon: <h1 style={{color:"#48e148 !important"}} className="fa fa-phone-volume text-primary m-0 mr-3 dark-greeen"></h1>,
  //   title: "24/7 Customer Support",
  // },
  
];

export const vendors = [
  {
    image: "../assets/img/vendor-1.jpg",
  },
  {
    image: "../assets/img/vendor-2.jpg",
  },
  {
    image: "../assets/img/vendor-3.jpg",
  },
  {
    image: "../assets/img/vendor-4.jpg",
  },
  {
    image: "../assets/img/vendor-5.jpg",
  },
  {
    image: "../assets/img/vendor-6.jpg",
  },
  {
    image: "../assets/img/vendor-7.jpg",
  },
  {
    image: "../assets/img/vendor-8.jpg",
  },
];

export const carousel = [
  {
    cover_img: "../assets/nt/diwali-complete-kit.png",
    cover_img_mobile: "../assets/nt/ganesh.png",
    title: "",
    description:
      "Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam",
    btn: "Shop Now",
  },
  // {
  //   cover_img: "../assets/nt/carousel-2.jpg",
  //   title: "",
  //   description:
  //     "Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam",
  //   btn: "Shop Now",
  // },
  // {
  //   cover_img: "../assets/img/carousel-3.jpg",
  //   title: "",
  //   description:
  //     "Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam",
  //   btn: "Shop Now",
  // },
];

export const footerTouch = [
  {
    icon: <i className="fa fa-map-marker-alt text-primary mr-3"></i>,
    contact: "718, Loha Mandi, Panchshil Nagar, Indore, Madhya Pradesh 452001",
    type:"address"
  },
  {
    icon: <i className="fa fa-envelope text-primary mr-3"></i>,
    contact: "ntincensesticks@gmail.com",
    type:"email"
  },
  {
    icon: <i className="fa fa-phone-alt text-primary mr-3"></i>,
    contact: "6264208349",
    type:"call"
  },
];

export const footer = [
  {
    id: 1,
    header: "Quick Shop",
  },
  {
    id: 2,
    header: "My Account",
  },
];
export const product_benifits = [
  // {
  //   id:1,
  //   image: "../assets/nt/product.png",
  //   name: "Free Delivery",
  // },
  // {
  //   id:2,
  //   image: "../assets/nt/biodegradable.png",
  //   name: "Eco Friendly",
  // },
  // // {
  // //   id:3,
  // //   image: "../assets/nt/eco-paint.png",
  // //   name: "Eco Paint",
  // // },
  // {
  //   id:4,
  //   image: "../assets/nt/pottery.png",
  //   name: "Pottery",
  // },
  // {
  //   id:5,
  //   image: "../assets/nt/growth.png",
  //   name: "Growth",
  // },
];

export const sidebar = [
  // {
  //   id: 1,
  //   header: "Filter By Price(in Rupees)",
  //   all: "All Price",
  //   total_quantity: "1000",
  //   subItem: [
  //     {
  //       variety: "0-100",
  //       quantity: "150",
  //     },
  //     {
  //       variety: "100-200",
  //       quantity: "150",
  //     },
  //     {
  //       variety: "200-300",
  //       quantity: "150",
  //     },
  //     {
  //       variety: "300-400",
  //       quantity: "150",
  //     },
  //     {
  //       variety: "400-500",
  //       quantity: "150",
  //     },
  //   ],
  // },
  {
    id: 1,
    header: "Filter By Fragnance",
    all: "All Fragnance",
    total_quantity: "1000",
    subItem: [
      {
        variety: "Rose",
        quantity: "30",
      },
      {
        variety: "Lavender",
        quantity: "11",
      },
      {
        variety: "Mogra",
        quantity: "20",
      },
      {
        variety: "Firangipani",
        quantity: "10",
      },
      {
        variety: "White Musk",
        quantity: "15",
      },
      {
        variety: "Pineapple",
        quantity: "15",
      },
      {
        variety: "Kewda",
        quantity: "15",
      },
      {
        variety: "Ruh Heena",
        quantity: "15",
      },
      {
        variety: "Muskan",
        quantity: "15",
      },
      {
        variety: "Loban",
        quantity: "15",
      },
      {
        variety: "Hawan",
        quantity: "15",
      },
      {
        variety: "Sandal",
        quantity: "15",
      },
    ],
  },
  // {
  //   id: 2,
  //   header: "Filter By Weight(in grams)",
  //   all: "All Weights",
  //   total_quantity: "1000",
  //   subItem: [
  //     {
  //       variety: "100",
  //       quantity: "60",
  //     },
  //     {
  //       variety: "200",
  //       quantity: "19",
  //     },
  //     {
  //       variety: "500",
  //       quantity: "150",
  //     },
  //     {
  //       variety: "1000",
  //       quantity: "16",
  //     },
  //     {
  //       variety: "2000",
  //       quantity: "15",
  //     },
  //   ],
  // },
];
