import ScrollToTop from "../../components/ScrollToTop";

export default function Shipping() {
  return (
    <>
      {/* <div className="top">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <marquee
                behavior="scroll"
                direction="left"
                loop="-1"
                className="scrolling-text"
              >
                NON - MILLENNIAL MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp;
                FREE SHIPPING PAN INDIA &nbsp;&nbsp;&nbsp;&nbsp;NON - MILLENNIAL
                MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN
                INDIA &nbsp;&nbsp;&nbsp;&nbsp;NON - MILLENNIAL MILLENNIALS CLUB
                &nbsp; &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN INDIANON -
                MILLENNIAL MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp; FREE
                SHIPPING PAN INDIA &nbsp;&nbsp;&nbsp;&nbsp;NON - MILLENNIAL
                MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN
                INDIANON - MILLENNIAL MILLENNIALS CLUB &nbsp; &nbsp; &nbsp;
                &nbsp; FREE SHIPPING PAN INDIANON - MILLENNIAL MILLENNIALS CLUB
                &nbsp; &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN INDIANON -
                MILLENNIAL MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp; FREE
                SHIPPING PAN INDIANON - MILLENNIAL MILLENNIALS CLUB &nbsp;
                &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN INDIANON - MILLENNIAL
                MILLENNIALS CLUB &nbsp; &nbsp; &nbsp; &nbsp; FREE SHIPPING PAN
                INDIANON - MILLENNIAL MILLENNIALS CLUB &nbsp; &nbsp; &nbsp;
                &nbsp; FREE SHIPPING PAN INDIA
              </marquee>
            </div>
          </div>
        </div>
      </div> */}
          <p style={{ textAlign: "center" }}>&nbsp;</p>

      <div className="container">
        <div className="policy">
          <p style={{ textAlign: "center" }}>
            <span style={{ fontSize: "17pt" }}>
              <span style={{ fontFamily: "Impact,monospace" }}>
                <span style={{ color: "#000" }}>
                  <strong>
                    <u>SHIPPING POLICY</u>
                  </strong>
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "center" }}>&nbsp;</p>
          <hr />
          <p style={{ textAlign: "start" }}>&nbsp;</p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>Welcome to </strong>
                </span>
              </span>
            </span>
            <span style={{ fontSize: "12pt" }}>
              <span style={{ fontFamily: "Lobster,cursive" }}>
                <span
                  style={{
                    color: "#000",
                    fontFamily: "Times New Roman,Times,serif",
                  }}
                >
                  <strong>Nt sticks</strong>
                </span>
              </span>
            </span>
            <span style={{ fontSize: "12pt" }}>
              <span style={{ fontFamily: "&quot" }}>
                <span style={{ color: "#000" }}>
                  <strong>!</strong>
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontSize: "12px" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ color: "#666" }}>
                  Thank you for shopping at Ntsticks.com ! We are committed to
                  providing you with the best possible shopping experience, and
                  part of that commitment involves transparent and reliable
                  shipping practices. Please take a moment to review our
                  shipping policy:
                </span>
              </span>
            </span>
          </p>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>1. Processing Time:</strong>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    Orders are typically processed and shipped within 3 business
                    days of receiving your payment and order details.
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    Please note that processing time may be extended during peak
                    seasons or promotional periods.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>2. Shipping Methods:</strong>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    We offer several shipping options, including e.g., standard
                    shipping, express shipping
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                <span style={{ fontSize: "12pt" }}>
                  <span style={{ color: "#666" }}>
                    <span style={{ fontSize: "12px" }}>
                      The estimated delivery time for each shipping method will
                      be provided after the checkout process
                    </span>
                    .
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>3. Shipping Costs:</strong>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    Shipping costs are calculated based on the weight of your
                    order, the shipping method selected, and your location.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>4. Free Shipping:</strong>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    We offer free standard shipping in india during some offer
                    periods.&nbsp;
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>5. Shipment Tracking:</strong>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    Once your order is shipped, you will receive a confirmation
                    email with a tracking number.
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    You can track your order by visiting [tracking link].
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>6. International Shipping:</strong>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    We offer international shipping worldwide. International
                    shipping costs and delivery times may vary.
                  </span>
                </span>
              </span>
            </li>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    Please note that international orders may be subject to
                    customs duties and taxes, which are the responsibility of
                    the recipient.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>7. Undeliverable Packages:</strong>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    If a package is undeliverable and returned to us due to an
                    incorrect address provided by the customer, the customer
                    will be responsible for any additional shipping costs.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>8. Shipping Delays:</strong>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    In rare cases, shipping delays may occur due to unforeseen
                    circumstances such as weather, customs, or carrier issues.
                    We appreciate your understanding in such situations.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>9. Shipping Partners:</strong>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    We work with reputable shipping partners to ensure the safe
                    and timely delivery of your orders.
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
              <span style={{ fontSize: "12pt" }}>
                <span style={{ color: "#000" }}>
                  <strong>10. Contact Information:</strong>
                </span>
              </span>
            </span>
          </p>
          <ul>
            <li style={{ listStyleType: "disc" }}>
              <span style={{ fontSize: "12px" }}>
                <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                  <span style={{ color: "#666" }}>
                    If you have any questions or concerns about our shipping
                    policy, please contact our customer service team at
                  </span>
                  <a
                    href="/cdn-cgi/l/email-protection#097d6c68644965687d6c30397a276067"
                    style={{ textDecoration: "none" }}
                  >
                    <span style={{ color: "#15c" }}>
                      <u>
                        <span
                          className="__cf_email__"
                          data-cfemail="aedacbcfc3eec2cfdacb979edd80c7c0"
                        >
                          [email&#160;protected]
                        </span>
                      </u>
                    </span>
                  </a>
                </span>
                <span style={{ fontFamily: "Arial,sans-serif" }}>
                  <span style={{ color: "#666" }}>&nbsp;</span>
                </span>
              </span>
            </li>
          </ul>
          <p style={{ textAlign: "start" }}>
            <span style={{ color: "#000" }}>
              <u>
                <strong>
                  <span style={{ fontFamily: "Times New Roman,Times,serif" }}>
                    <span style={{ fontSize: "12px" }}>
                      Thank you for choosing us!
                    </span>
                  </span>
                </strong>
              </u>
            </span>
          </p>{" "}
        </div>
      </div>
      <ScrollToTop />
    </>
  );
}
