import { useEffect } from "react"
import {
    getCartTotal,
    clearCart
  } from "../../redux/CartSlice";
  import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "../../components/ScrollToTop";
import Heading from "../../common/Heading";


export default function Confirmation(){
    const dispatch = useDispatch();

    useEffect(()=>{
    dispatch(clearCart());
    dispatch(getCartTotal());
    },[])
    return(
        <>
                <Heading title="Home" subtitle="Confirmation Page" />

        <div>

            <div className="popup-parent">

                <div className="confirmation-popup">
                    <div><img style={{margin:"10px"}} src="../../assets/nt/patch-check.svg"/></div>
                    <label >Thank you for ordering</label></div></div>
                    </div>
                    <ScrollToTop />

        </>
    )
}