import React from "react";
import Features from "../../components/Features";
import Product from "../../components/Product";
import Banner from "../../components/Banner";
import Categories from "../../components/Categories";
import Offers from "../../components/Offers";
import Vendor from "../../components/Vendor";
import ProceedToCheckoutView from "../../components/ProceedToCheckoutView";
import Blogs from "../../components/Blogs";
import { blogs_show_more } from "../../data/Data";

export default function Home() {
  return (
    <>
      <Banner />
      <Features />
      <Categories />
      <Product />
      <Blogs blogsData={blogs_show_more}/>
      <Offers />
      <ProceedToCheckoutView/>
      {/* <Vendor /> */}
    </>
  );
}
